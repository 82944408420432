<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            style="height: 100vh;">
            <v-row
              align="center"
              style="height:calc(100vh - 100px)">
              <v-col>
                <v-card  
                  flat
                  color="transparent">
                  <v-card-text
                    class="text-center">
                    <v-img
                      width="175"
                      height="auto"
                      class="ma-auto"
                      alt="vuetify"
                      :src="require('@/assets/logo/favicon.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="bory-2 text-capitalize mb-2">
                      Selamat Datang Di
                    </div>

                    <div
                      class="text-h4 color-default mb-16">
                      Rapat FPR
                    </div>
                  </v-card-text>

                  <v-card-text
                    class="text-center">
                    <div
                      class="body-2 line-text-second mb-16">
                      Reset password akun berhasil, terima kasih Anda telah melakukan reset password untuk keamanan akun Anda.
                    </div>

                    <v-btn
                      to="/"
                      width="200"
                      large
                      rounded
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize btn_auth">
                      <div
                        class="btn_auth_in">
                        Masuk Sekarang
                      </div>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div>
              <v-row
                justify="center">
                <v-col 
                  cols="2">
                  <v-divider 
                    style="border-top: 3px solid #4d585b; border-radius: 50px;" />
                </v-col>
              </v-row>

              <div
                class="body-2 text-center mt-2"
                style="opacity: 70%;">
                Copyright@{{ new Date().getFullYear() }} <strong>rapatfpr.klaten.go.id</strong>
              </div>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        // SEO
        content: {
          url: '/auth/success',
          title: 'Reset Password Terkirim',
          description: 'Reset Password Terkirim Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>