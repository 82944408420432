<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="pt-2 pb-6 px-1">
              <!-- <v-row>
                <v-col
                  v-for="(item, index) in menu"
                  :key="index"
                  cols="4"
                  class="mb-4 px-0 d-flex align-center justify-center">
                  <v-divider
                    :style="step >= item.value && index > 0 ? 'border-bottom: 2px solid #2391d0;' : index > 0 ? 'border-bottom: 2px solid #00000020;' : 'border: 0px solid #FFFFFF;'" />

                  <v-btn
                    @click="goTo(item.value)"
                    width="110"
                    rounded
                    elevation="0"
                    class="body-2 font-weight-bold text-capitalize white--text px-2 d-flex align-center justify-start"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;"
                    :color="step >= item.value ? '#2391d0' : '#00000020'">
                    <v-avatar
                      size="24"
                      color="#0000001a">
                      {{ item.value }}
                    </v-avatar>
                    
                    <span
                      class="ml-1">
                      {{ item.title }}
                    </span>
                  </v-btn>

                  <v-divider
                    :style="step > item.value && index < menu.length - 1 ? 'border-bottom: 2px solid #2391d0;' : index < menu.length - 1 ? 'border-bottom: 2px solid #00000020;' : 'border: 0px solid #FFFFFF;'" />
                </v-col>
              </v-row> -->

              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide 
                    v-for="(item, index) in menu" 
                    :key="index" 
                    class="py-3"
                    style="max-width: 150px;">
                    <div
                      class="d-flex align-center">
                      <v-divider
                        :style="step >= item.value && index > 0 ? 'border-bottom: 2px solid #2391d0;' : index > 0 ? 'border-bottom: 2px solid #00000020;' : 'border: 0px solid #FFFFFF;'" />

                      <v-btn
                        @click="goTo(item.value)"
                        width="110"
                        rounded
                        elevation="0"
                        class="body-2 font-weight-bold text-capitalize white--text px-2 d-flex align-center justify-start"
                        style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;"
                        :color="step >= item.value ? '#2391d0' : '#00000020'">
                        <v-avatar
                          size="24"
                          color="#0000001a">
                          <span v-if="step <= item.value">
                            {{ item.value }}
                          </span>

                          <v-icon v-else
                            small>
                            mdi-check-bold
                          </v-icon>
                        </v-avatar>
                        
                        <span
                          class="mx-auto">
                          {{ item.title }}
                        </span>
                      </v-btn>

                      <v-divider
                        :style="step > item.value && index < menu.length - 1 ? 'border-bottom: 2px solid #2391d0;' : index < menu.length - 1 ? 'border-bottom: 2px solid #00000020;' : 'border: 0px solid #FFFFFF;'" />
                    </div>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </v-card-text>

            <Topic v-if="step === 1"
              :form="form"
              @step="step = $event" />

            <Pemohon v-if="step === 2"
              :form="form"
              @step="step = $event" />

            <Subject v-if="step === 3"
              :form="form"
              @step="step = $event" />

            <Participant v-if="step === 4"
              :form="form"
              @step="step = $event" />
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        step: 1,
        menu: [
          {
            title: 'Topik',
            value: 1
          },
          {
            title: 'Pemohon',
            value: 2
          },
          {
            title: 'Subjek',
            value: 3
          },
          {
            title: 'Anggota',
            value: 4
          }
        ],
        form: {
          id: '',
          title: '',
          content: '',
          image: '',
          lampiran: [],
          subject: [],
          participant: [],
          extra: {
            type_topic: '',
            data_topic: [],

            // DATA PEMOHON
            nama_pemohon: '',
            npwp_pemohon: '',
            alamat_pemohon: '',
            phone_pemohon: '',
            email_pemohon: '',
            kode_kbli_pemohon: '',
            judul_kbli_pemohon: '',
            skala_usaha_pemohon: '',
            lokasi_pemohon: {
              alamat: '',
              desa_code: '',
              desa_name: '',
              kecamatan_code: '',
              kecamatan_name: '',
              kabupaten_code: '',
              kabupaten_name: '',
              provinsi_code: '',
              provinsi_name: '',
              koordinat: []
            },
            document_type: '',
            luas_tanah_pemohon: '',
            rencana_teknis_pemohon: ''
          },
          document_type: '',
          type: 'pkkpr',
          surat_no: '',
          surat_date: '',
          is_schedule: true,
          start_date: '',
          end_date: ''
        },
        process: {
          load: false
        },
        // SEO
        content: {
          url: `/forum/form/${this.$route.params.id}`,
          title: 'Ubah Topik',
          description: 'Ubah Topik Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Topic: () => import(/* webpackPrefetch: true */ '@/components/forum/topic.vue'),
      Pemohon: () => import(/* webpackPrefetch: true */ '@/components/forum/pemohon.vue'),
      Subject: () => import(/* webpackPrefetch: true */ '@/components/forum/subject.vue'),
      Participant: () => import(/* webpackPrefetch: true */ '@/components/forum/participant.vue'),
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.step = this.$route.query.step === undefined ? 1 : parseInt(this.$route.query.step)

      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/topic/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            let item = response.data

            this.form = {
              id: item.t_id,
              title: item.t_title,
              content: item.t_content,
              image: item.t_image,
              lampiran: item.t_lampiran,
              subject: item.t_subject,
              participant: item.t_participant,
              extra: item.t_extra,
              // extra: {
              //   type_topic: item.t_extra.type_topic,
              //   data_topic: item.t_extra.data_topic,

              //   // DATA PEMOHON
              //   nama_pemohon: '',
              //   npwp_pemohon: '',
              //   alamat_pemohon: '',
              //   phone_pemohon: '',
              //   email_pemohon: '',
              //   kode_kbli_pemohon: '',
              //   judul_kbli_pemohon: '',
              //   skala_usaha_pemohon: '',
              //   lokasi_pemohon: {
              //     alamat: '',
              //     desa_code: '',
              //     desa_name: '',
              //     kecamatan_code: '',
              //     kecamatan_name: '',
              //     kabupaten_code: '',
              //     kabupaten_name: '',
              //     provinsi_code: '',
              //     provinsi_name: '',
              //     koordinat: []
              //   },
              //   luas_tanah_pemohon: '',
              //   rencana_teknis_pemohon: ''
              // },
              document_type: item.t_document_type,
              type: item.t_type,
              surat_no: item.t_surat_no,
              surat_date: item.t_surat_date,
              is_schedule: item.t_is_schedule === 0 ? false : true,
              start_date: item.t_start_date,
              end_date: item.t_end_date
            }
          } 
        })
      },
      goTo (val) {
        this.step = val

        this.$router.push({ path: `/forum/form/${this.$route.params.id}`, query: { step: val }})
      }
    }
  }
</script>