<template>
  <div>
    <WindowsBottomNavigation 
      v-model="selected"
      :options="menu"
      foregroundColor="#2391d0"
      border-color="#2391d0"
      backgroundColor="#FFFFFF">
      <template #icon="{ props }">
        <v-img 
          :class="selected === props.id ? 'mb-2' : ''"
          :style="selected === props.id ? '' : 'opacity: 50%;'"
          contain
          width="22"
          height="22"
          alt="rapatfpr" 
          :src="props.icon"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                color="#2391d0">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>

      <template #title="{ props }">
        <div
          class="color-default">
          {{ props.title }}
        </div>
      </template>
    </WindowsBottomNavigation>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        selected: 1,
        menu: [
          {
            id: 1,
            title: "Beranda",
            icon: require('@/assets/image/footer/home.png'),
            path: { name: "home" }
          },
          {
            id: 2,
            title: "Forum",
            icon: require('@/assets/image/footer/chat.png'),
            path: { name: "forum" }
          },
          {
            id: 3,
            title: "Profil",
            icon: require('@/assets/image/footer/settings.png'),
            path: { name: "profile" }
          }
        ]
      }
    },
    components: {
      
    },
    watch: {
      $route: function(val) {
        this.menu.map(obj => {
          if (obj.path.name === val.name) this.selected = obj.id
        })
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      },
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.menu.map(obj => {
        if (obj.path.name === this.$route.name) this.selected = obj.id
      })
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  .wn-btn-container-foreground {
    max-width: 460px !important;
    height: 56px !important;
    z-index: 1 !important;
  }
</style>