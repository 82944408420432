var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('TopToolbar'),_vm._v(" "),(!_vm.firstLoad && 
          _vm.$route.path !== '/' &&
          _vm.$route.path !== '/auth/forgot' &&
          _vm.$route.path !== '/auth/check' &&
          _vm.$route.path !== '/auth/success' &&
          _vm.$route.name !== 'auth-reset-slug' &&
          _vm.$route.name !== 'forum-id' &&
          
          _vm.$route.name !== 'forum-form' &&
          _vm.$route.name !== 'forum-form-id' &&
          _vm.$route.path !== '/admin')?_c('BottomNav'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }