<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="py-0"
              style="height: calc(100vh - 350px); 
                  overflow-y: auto;">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="nama_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Pemohon
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Firmansyah Abdul Rafi"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.nama_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <!-- <ValidationProvider 
                    name="npwp_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      NPWP
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. 00.XXX.XXX.X-XXX.XXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.npwp_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider> -->

                  <div>
                    <div class="body-2 mb-2">
                      NPWP
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. 00.XXX.XXX.X-XXX.XXX"
                      :append-icon="form.extra.npwp_pemohon !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.npwp_pemohon"
                      :success="form.extra.npwp_pemohon !== ''">
                    </v-text-field>
                  </div>

                  <ValidationProvider 
                    name="alamat_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Alamat Pemohon
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      rows="4"
                      placeholder="Contoh. Tuliskan alamat disini . . ."
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.alamat_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 20px;">
                    </v-textarea>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="phone_pemohon" 
                    rules="min:8|max:13"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Telepon
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. 081332XXXXXX"
                      :append-icon="form.extra.phone_pemohon !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.phone_pemohon"
                      :success="form.extra.phone_pemohon !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="email_pemohon" 
                    rules="email"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Email
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. XXXX@gmail.com"
                      :append-icon="form.extra.email_pemohon !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.email_pemohon"
                      :success="form.extra.email_pemohon !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="kode_kbli_pemohon" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kode KBLI
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. XXXXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.kode_kbli_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="judul_kbli_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Judul KBLI
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Perdagangan Besar"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.judul_kbli_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.extra.type_topic === 'BERUSAHA'"
                    name="skala_usaha_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Skala Usaha
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <!-- <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. XXXXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.skala_usaha_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field> -->

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Mikro"
                      :append-icon="!valid ? '' : 'mdi-check-circle-outline'"
                      
                      :items="['Menengah', 'Besar']"
                      item-text="ds_title"
                      item-value="ds_id"
                      item-color="#2391d0"

                      v-model="form.extra.skala_usaha_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>

                  <div
                    class="body-2 font-weight-bold black--text">
                    Lokasi Usaha
                  </div>

                  <v-divider
                    class="mt-2 mb-4"
                    style="border-bottom: 3px solid #00000020; border-radius: 50px;" />
                  
                  <!-- <ValidationProvider 
                    name="lokasi_pemohon.alamat" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Alamat Lokasi Usaha
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      rows="4"
                      placeholder="Contoh. Tuliskan alamat disini . . ."
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.lokasi_pemohon.alamat"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 20px;">
                    </v-textarea>
                  </ValidationProvider> -->

                  <div>
                    <div class="body-2 mb-2">
                      Alamat Lokasi Usaha
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      rows="4"
                      placeholder="Contoh. Tuliskan alamat disini . . ."
                      :append-icon="form.extra.lokasi_pemohon.alamat !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.lokasi_pemohon.alamat"
                      :success="form.extra.lokasi_pemohon.alamat !== ''"
                      style="border-radius: 20px;">
                    </v-textarea>
                  </div>

                  <ValidationProvider
                    name="lokasi_pemohon.provinsi_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Provinsi
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      
                      placeholder="Contoh. Jawa Tengah"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.province"
                      :loading="process.province"
                      
                      :items="config.province"
                      item-value="provinsi_code"
                      item-text="provinsi_name"
                      item-color="#2391d0"

                      v-model="form.extra.lokasi_pemohon.provinsi_code"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider
                    name="lokasi_pemohon.kabupaten_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kabupaten
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      
                      placeholder="Contoh. Klaten"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.city"
                      :loading="process.city"
                      
                      :items="config.city"
                      item-value="kabupaten_code"
                      item-text="kabupaten_name"
                      item-color="#2391d0"

                      v-model="form.extra.lokasi_pemohon.kabupaten_code"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider
                    name="lokasi_pemohon.kecamatan_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kecamatan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      
                      placeholder="Contoh. Trucuk"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.subdistrict"
                      :loading="process.subdistrict"
                      
                      :items="config.subdistrict"
                      item-value="kecamatan_code"
                      item-text="kecamatan_name"
                      item-color="#2391d0"

                      v-model="form.extra.lokasi_pemohon.kecamatan_code"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider
                    name="lokasi_pemohon.desa_code" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Desa/Kelurahan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      
                      placeholder="Contoh. Kradenan"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.village"
                      :loading="process.village"
                      
                      :items="config.village"
                      item-value="desa_code"
                      item-text="desa_name"
                      item-color="#2391d0"

                      v-model="form.extra.lokasi_pemohon.desa_code"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-autocomplete>
                  </ValidationProvider>

                  <div
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Polygon
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <uploadzip 
                      v-model="upload.zip">
                      <div 
                        slot="activator" 
                        class="cursor-pointer">
                        <v-btn
                          :disabled="process.polygon"
                          :loading="process.polygon"
                          rounded
                          color="#2391d033"
                          elevation="0"
                          class="text-capitalize color-default">
                          <v-icon small>mdi-map-marker-plus-outline</v-icon>
                          
                          <span
                            class="ml-1">
                            Upload Polygon
                          </span>
                        </v-btn>
                      </div>
                    </uploadzip>

                    <div v-if="form.extra.lokasi_pemohon.koordinat.length > 0"
                      class="mt-6">
                      <gmap-map
                        ref="gmap"
                        :center="center"
                        :zoom="18"
                        :scrollwheel="false"
                        map-type-id="satellite"
                        :options="{
                          zoomControl: true,
                          mapTypeControl: true,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: false
                        }"
                        style="width:100%; height: 50vh;">
                        <gmap-polygon 
                          :paths="form.extra.lokasi_pemohon.koordinat"
                          :options="options">
                        </gmap-polygon>
                      </gmap-map>
                    </div>
                  </div>

                  <ValidationProvider
                    name="luas_tanah_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Luas Tanah (M<sup>2</sup>)
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"

                      type="number"
                      onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                      min="0"
                      
                      placeholder="Contoh. 1720"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      suffix="M2"

                      v-model="form.extra.luas_tanah_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="rencana_teknis_pemohon" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Rencana Teknis Bangunan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Ada (Terlampir)"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.extra.rencana_teknis_pemohon"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="rapatfpr" 
                      :src="require('@/assets/icon/data_pemohon.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Lengkapi data Pemohon yang akan di diskusikan Anggota, kemudian klik <b class="font-italic">Selanjutnya</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Selanjutnya
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="goBack()"
                      :disabled="process.form"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-arrow-left-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Kembali
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        upload: {
          zip: null
        },
        center: {
          // PENDOPO
          lat: -7.71210962,
          lng: 110.59174574
        },
        options: {
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: "#FF0000",
          fillOpacity: 0.35
        },
        autocomplete: {
          province: '',
          city: '',
          subdistrict: '',
          village: ''
        },
        config: {
          province: [],
          city: [],
          subdistrict: [],
          village: []
        },
        form_subject: {
          topic_id: '',
          data_subject: []
        },
        process: {
          form: false,
          province: false,
          city: false,
          subdistrict: false,
          village: false,

          polygon: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      uploadzip: () => import(/* webpackPrefetch: true */ '@/components/upload/zip.vue')
    },
    watch: {
      'form.extra.lokasi_pemohon.provinsi_code': function() {
        let t = this
        let pro = this.config.province.filter(function(v) {
          return t.form.extra.lokasi_pemohon.provinsi_code == v.provinsi_code
        })

        if (pro.length > 0) {
          this.form.extra.lokasi_pemohon.provinsi_name = pro[0].provinsi_name

          this.form.extra.lokasi_pemohon.kabupaten_code = ''
          this.form.extra.lokasi_pemohon.kabupaten_name = ''

          this.form.extra.lokasi_pemohon.kecamatan_code = ''
          this.form.extra.lokasi_pemohon.kecamatan_name = ''

          this.form.extra.lokasi_pemohon.desa_code = ''
          this.form.extra.lokasi_pemohon.desa_name = ''
        }

        this.static().city()
      },
      'form.extra.lokasi_pemohon.kabupaten_code': function() {
        let t = this
        let cit = this.config.city.filter(function(v) {
          return t.form.extra.lokasi_pemohon.kabupaten_code == v.kabupaten_code
        })

        if (cit.length > 0) {
          this.form.extra.lokasi_pemohon.kabupaten_name = cit[0].kabupaten_name

          this.form.extra.lokasi_pemohon.kecamatan_code = ''
          this.form.extra.lokasi_pemohon.kecamatan_name = ''

          this.form.extra.lokasi_pemohon.desa_code = ''
          this.form.extra.lokasi_pemohon.desa_name = ''
        }

        this.static().subdistrict()
      },
      'form.extra.lokasi_pemohon.kecamatan_code': function() {
        let t = this
        let sub = this.config.subdistrict.filter(function(v) {
          return t.form.extra.lokasi_pemohon.kecamatan_code == v.kecamatan_code
        })

        if (sub.length > 0) {
          this.form.extra.lokasi_pemohon.kecamatan_name = sub[0].kecamatan_name

          this.form.extra.lokasi_pemohon.desa_code = ''
          this.form.extra.lokasi_pemohon.desa_name = ''
        }

        this.static().village()
      },
      'form.extra.lokasi_pemohon.desa_code': function() {
        let t = this
        let vil = this.config.village.filter(function(v) {
          return t.form.extra.lokasi_pemohon.desa_code == v.desa_code
        })

        if (vil.length > 0) {
          this.form.extra.lokasi_pemohon.desa_name = vil[0].desa_name
        }
      },
      'autocomplete.province': function() {
        this.static().province()
      },
      'autocomplete.city': function() {
        this.static().city()
      },
      'autocomplete.subdistrict': function() {
        this.static().subdistrict()
      },
      'autocomplete.village': function() {
        this.static().village()
      },

      'upload.zip': {
        handler: function () {
          if (this.upload.zip) {
            this.process.polygon = this.upload.zip.process

            if (!this.process.polygon) {
              // CALLBACK FROM UPLOAD ZIP POLYGON

              this.form.extra.lokasi_pemohon.koordinat = []

              let data = this.upload.zip.data.geo_json[0].coordinates[0]

              data.map(obj => {
                this.form.extra.lokasi_pemohon.koordinat.push({
                  lat: obj[1],
                  lng: obj[0]
                })
              })

              this.center = this.form.extra.lokasi_pemohon.koordinat[0]
            }
          }
        },
        deep: true
      },
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchMasterSubject()
    },
    methods: {
      goBack () {
        let url_to = this.$route.params.id === undefined ? '/forum/form' : `/forum/form/${this.$route.params.id}`

        this.$emit('step', 1)
        this.$router.push({ path: url_to, query: { step: 1 }})
      },
      static () {
        let t = this
        return {
          async province () {
            t.process.province = true

            let params = {
              search: t.autocomplete.province
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/ilokasi/provinsi`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.province = false

                t.config.province = response.data
              }
            })
          },
          async city () {
            t.process.city = true

            let params = {
              kabupaten_provinsi_code: t.form.extra.lokasi_pemohon.provinsi_code,
              search: t.autocomplete.city
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/ilokasi/kabupaten`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.city = false

                t.config.city = response.data
              }
            })
          },
          async subdistrict () {
            t.process.subdistrict = true

            let params = {
              kecamatan_kabupaten_code: t.form.extra.lokasi_pemohon.kabupaten_code,
              search: t.autocomplete.subdistrict
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/ilokasi/kecamatan`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.subdistrict = false

                t.config.subdistrict = response.data
              }
            })
          },
          async village () {
            t.process.village = true

            let params = {
              desa_kecamatan_code: t.form.extra.lokasi_pemohon.kecamatan_code,
              search: t.autocomplete.village
            }

            await t.$axios.$get(`${process.env.PREFIX_MASTER}/ilokasi/desa`, { params })
            .then(response => {
              if (response.code == 200) {
                t.process.village = false

                t.config.village = response.data
              }
            })
          }
        }
      },
      async save () {
        this.message.error = ''
        
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/discussion/topic/update`, this.form)
          .then((response) => {

            if (response.code === 200) {
              // this.process.form = false
              
              // this.$emit('step', 3)
              // this.$router.push({ path: `/forum/form/${response.data.t_id}`, query: { step: 3 }})

              this.save_subject()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      // SAVE SUBJECT
      async fetchMasterSubject () {
        this.form_subject = {
          topic_id: '',
          data_subject: []
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master/template_discussion_subject`)
        .then((response) => {
          if (response.code === 200) {
            let data = response.data

            this.form_subject.topic_id = this.$route.params.id

            data.map(obj => {
              this.form_subject.data_subject.push({
                ds_id: obj.ds_id,
                ds_title: obj.ds_title,
                s_title: obj.ds_title !== 'Jenis peruntukan Pemanfaatan Ruang' ? '' : [],
                s_content: ''
              })
            })
            
            if (this.form.extra.lokasi_pemohon.koordinat.length > 0) this.center = this.form.extra.lokasi_pemohon.koordinat[0]
          } 
        })
      },
      async save_subject () {
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/discussion/subject/create_bulk`, this.form_subject)
        .then((response) => {

          if (response.code === 200) {
            this.process.form = false
              
            this.$emit('step', 3)
            this.$router.push({ path: `/forum/form/${this.$route.params.id}`, query: { step: 3 }})
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>