<template>
  <div>
    <v-bottom-sheet
      v-model="sheet"
      fullscreen
      hide-overlay
      persistent>
      <v-card flat>
        <div 
          v-if="webcam.isCameraOpen" 
          v-show="!webcam.isLoading" 
          class="camera-box d-flex align-center justify-center" 
          :class="{ 'flash' : webcam.isShotPhoto }">
          <div 
            id="camera-shutter"
            class="camera-shutter" 
            :class="{'flash' : webcam.isShotPhoto}">
          </div>

          <video 
            v-show="!webcam.isPhotoTaken" 
            id="video"
            ref="camera" 
            autoplay>
          </video>
          
          <canvas 
            v-show="webcam.isPhotoTaken" 
            id="canvas" 
            ref="canvas">
          </canvas>
        </div>

        <!-- <div class="d-flex align-center justify-center"
          style="position: absolute; bottom: 30px; left: 0; right: 0;"> -->
          <!-- BACK / STOP -->
          <!-- <v-btn
            @click="stopCameraStream()"
            fab
            width="50"
            height="50"
            color="red"
            style="box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">
            <v-icon color="white" size="30">
              mdi-arrow-left-thick
            </v-icon>
          </v-btn> -->

          <!-- CAPTURE -->
          <!-- <v-btn
            @click="takePhoto()"
            class="btn-webcam mx-5">
            <v-icon color="white" size="36">
              mdi-image-filter-center-focus
            </v-icon>
          </v-btn> -->

          <!-- ROLL -->
          <!-- <v-btn
            @click="flibCamera()"
            fab
            width="50"
            height="50"
            color="blue"
            style="box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;">
            <v-icon color="white" size="30">
              mdi-camera-flip-outline
            </v-icon>
          </v-btn>
        </div> -->

        <v-bottom-navigation
          background-color="transparent"
          grow
          dark
          :shift="false"
          fixed
          style="height: 150px;"
          class="d-flex align-center">
          <!-- BACK / STOP -->
          <v-btn
            @click="stopCameraStream()"
            fab
            width="50"
            height="50"
            color="red"
            style="box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; border-radius: 50px;">
            <v-icon color="white" size="30">
              mdi-arrow-left-thick
            </v-icon>
          </v-btn>

          <!-- CAPTURE -->
          <v-btn
            @click="takePhoto()"
            class="btn-webcam mx-5">
            <v-icon color="white" size="36">
              mdi-image-filter-center-focus
            </v-icon>
          </v-btn>

          <!-- ROLL -->
          <v-btn
            @click="flibCamera()"
            fab
            width="50"
            height="50"
            color="blue"
            style="box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; border-radius: 50px;">
            <v-icon color="white" size="30">
              mdi-camera-flip-outline
            </v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    name: 'upload-camera',
    props: {
      sheet: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      webcam: {
        isMode: false,
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false
      },
      message: {
        success: '',
        error: ''
      },
      process: {
        form: false
      }
    }),
    mounted () {
      if (this.sheet) this.createCameraElement()
    },
    methods: {
      // FUCNTION WEBCAM
      async createCameraElement () {
        this.webcam.isCameraOpen = true
        this.webcam.isPhotoTaken = false

        this.webcam.isLoading = true
        
        const constraints = (window.constraints = {
          audio: false,
          video: {
            facingMode: this.webcam.isMode ? 'user' : 'environment'
          }
        })

        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        this.$refs.camera.srcObject = stream
        this.$refs.camera.play()

        this.webcam.isLoading = false
      },

      // FLIB CAMERA STREAM
      flibCamera () {
        this.webcam.isMode = !this.webcam.isMode ? true : false

        let tracks = this.$refs.camera.srcObject.getTracks()

        tracks.forEach(track => {
          track.stop()
        })

        this.createCameraElement()
      },

      // FUNCTION TAKE PHOTO WEBCAM
      takePhoto () {
        if(!this.webcam.isPhotoTaken) {
          this.webcam.isShotPhoto = true

          const FLASH_TIMEOUT = 50

          setTimeout(() => {
            this.webcam.isShotPhoto = false
          }, FLASH_TIMEOUT)
        }
        
        this.webcam.isPhotoTaken = !this.webcam.isPhotoTaken

        const shutter = document.getElementById('camera-shutter')
        const canvas = document.getElementById('canvas')
        const context = this.$refs.canvas.getContext('2d')

        shutter.style.width = '100%'
        shutter.style.height = '100vh'

        canvas.width = window.innerWidth
        canvas.height = window.innerHeight - 500
        
        canvas.style.width = '100%'
        canvas.style.height = '100vh'
        
        context.drawImage(this.$refs.camera, 0, 0, canvas.width, canvas.height)

        const img = canvas.toDataURL('image/jpeg')

        fetch(img)
        .then(res => res.blob())
        .then(blob => {
          let formData = new FormData(),
              file = new File([blob], 'dpupr.jpeg')
              formData.append('api-key', 'c788bfb9d73c9ced96c369186ea27uop')
              formData.append('client-key', 'dpub2dd76ae5199f20791c87bfdc1453pr')
              formData.append('folder_code', '589443')
              formData.append('files[]', file)
              formData.append('user_id', this.$store.state.member.auth.user.id)

          this.process.form = true
          this.$emit('input', {
            process: this.process.form
          })

          this.$emit('frame', false)

          fetch(`${process.env.PREFIX_CDN}/image`, {
            method: 'POST',
            body: formData
          })
          .then(res => res.json())
          .then(response => {
            this.process.form = false

            this.$emit('input', {
              id: response.data[0].id,
              type: response.data[0].type,
              data: response.data[0].data,
              process: this.process.form
            })

            this.stopCameraStream()
          })
        })
      },

      // FUNCTION STOP WEBCAM
      stopCameraStream () {
        this.webcam.isPhotoTaken = false

        let tracks = this.$refs.camera.srcObject.getTracks()

        tracks.forEach(track => {
          track.stop()

          this.$emit('close', false)
        })
      }
    }
  }
</script>

<style lang="scss">
  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 30px;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
    }
  }

  video {
    // box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }

  #canvas {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    object-fit: cover;
  }
</style>