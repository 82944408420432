<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            class="mt-2"
            color="transparent">
            <v-card-text
              class="pt-0">
              <v-row>
                <v-col
                  cols="6">
                  <v-card
                    flat
                    height="226"
                    color="#FFFFFF40"
                    class="border-radius">
                    <v-card-text>
                      <v-img 
                        width="100%"
                        height="140"
                        alt="rapatfpr" 
                        :src="user.image === '' ? require('@/assets/image/member_default.jpg') : user.image"
                        class="ma-auto border-radius">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div
                        class="body-1 font-weight-bold black--text mt-3 one-line">
                        {{ user.fullname }}
                      </div>

                      <div
                        class="caption">
                        {{ user.nik }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <v-row>
                    <v-col
                      cols="12">
                      <v-card
                        flat
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-card-text>
                          <div
                            class="d-flex align-center mb-6">
                            <div
                              class="body-2">
                              Admin FPR
                            </div>

                            <v-spacer />

                            <!-- <div
                              style="opacity: 60%;">
                              <v-icon size="20">mdi-chevron-right</v-icon>
                            </div> -->
                          </div>

                          <v-skeleton-loader
                            v-if="process.user"
                            :loading="process.user"
                            type="text">          
                          </v-skeleton-loader>

                          <div v-if="!process.user"
                            class="d-flex align-center">
                            <div
                              class="body-1 font-weight-bold black--text">
                              {{ pagination.user.total_data }}
                            </div>

                            <v-spacer />

                            <v-avatar
                              v-for="(item, index) in list.user"
                              :key="index"
                              v-show="index < 3"
                              size="30"
                              style="border: 2px solid #FFF;"
                              :style="index === 1 ? 'position: absolute; right: 60px; z-index: 2;' : 
                                      index === 2 ? 'position: absolute; right: 38px; z-index: 1;' : ''">
                              <v-img 
                                width="100%"
                                height="100%"
                                alt="rapatfpr" 
                                :src="item.image === '' ? require('@/assets/image/member_default.jpg') : item.image"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    
                    <v-col
                      cols="12">
                      <v-card
                        flat
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-card-text>
                          <div
                            class="d-flex align-center mb-4">
                            <div
                              class="body-2">
                              Diskusi
                            </div>

                            <v-spacer />

                            <nuxt-link
                              to="/forum">
                              <div
                                style="opacity: 60%;">
                                <v-icon size="20">mdi-chevron-right</v-icon>
                              </div>
                            </nuxt-link>
                          </div>

                          <div
                            class="body-1 font-weight-bold black--text">
                            <v-skeleton-loader
                              :loading="process.topic"
                              type="text">
                              {{ pagination.topic.total_data }} Ajuan
                            </v-skeleton-loader>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- <v-card-text>
              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2 font-weight-bold black--text">
                  Mulai Diskusi
                </div>

                <v-spacer />

                <div>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </div>
              </div>

              <div>
                <v-chip
                  v-for="(item, index) in process.master ? 4 : list.master"
                  :key="index"
                  v-show="index < 4"
                  color="#00000014"
                  class="caption mr-3 mb-3 px-4">
                  <v-skeleton-loader
                    :loading="process.master"
                    type="text"
                    :class="process.master ? 'mr-3 mb-3 px-4' : ''">
                    {{ item.td_title }}
                  </v-skeleton-loader>
                </v-chip>
              </div>
            </v-card-text> -->

            <v-card-text
              class="mb-4">
              <v-card
                color="#2391d0"
                class="bg_group"
                style="border-radius: 15px; box-shadow: rgba(35, 145, 208) 0px 25px 20px -20px;">
                <v-card-text
                  class="py-2">
                  <v-row 
                    align="center">
                    <v-col 
                      cols="4">
                      <v-img 
                        contain
                        width="75"
                        height="75"
                        alt="kominfo" 
                        :src="require('@/assets/icon/forum.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col 
                      cols="8" 
                      class="white--text">
                      <div 
                        class="title mb-2">
                        #Yuk Diskusi
                      </div>

                      <div 
                        class="body-2 white--text font-weight-light black--text line-text-first">
                        Diskusikan permohonan PKKPR Berusaha dan PKKPR Nonberusaha yang diajukan oleh masyarakat.
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text v-if="process.topic || list.topic.length > 0"
              class="pb-2">
              <div
                class="d-flex align-center">
                <div
                  class="body-2 font-weight-bold black--text">
                  Daftar Ajuan PKKPR
                </div>

                <v-spacer />

                <nuxt-link
                  to="/forum">
                  <div>
                    <v-icon>mdi-chevron-right</v-icon>
                  </div>
                </nuxt-link>
              </div>
            </v-card-text>

            <div>
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide 
                    v-for="(item, index) in process.topic ? 3 : list.topic" 
                    :key="index" 
                    class="py-3" 
                    :class="index === 0 ? 'pl-4 pr-3' : index < list.topic.length - 1 ? 'pr-3' : 'pr-4'"
                    style="width: 300px;">
                    <v-skeleton-loader
                      :loading="process.topic"
                      type="article">          
                      <div>
                        <v-card v-if="!process.topic && list.topic.length > 0"
                          :to="`/forum/${item.id}`"
                          flat
                          width="300"
                          color="#FFFFFF40"
                          class="border-radius">
                          <div
                            class="pa-4">
                            <div
                              class="caption mb-2 one-line d-flex align-center">
                              <v-icon small>mdi-account-circle-outline</v-icon>
                              
                              <span
                                class="ml-1"
                                style="opacity: 60%;">
                                {{ item.t_extra.nama_pemohon === '' ? 'Tidak Diketahui' : item.t_extra.nama_pemohon }}
                              </span>
                            </div>

                            <div
                              class="body-2 line-text-first black--text one-line mb-2">
                              {{ item.t_extra.judul_kbli_pemohon === '' ? 'Tidak Diketahui' : item.t_extra.judul_kbli_pemohon }}
                            </div>

                            <div
                              class="body-2 black--text line-text-first one-line mb-4"
                              style="opacity: 60%;">
                              <!-- {{ item.t_extra.lokasi_pemohon.alamat === '' ? 'Tidak Diketahui' : item.t_extra.lokasi_pemohon.alamat }} -->
                              <span v-if="item.t_extra.lokasi_pemohon.alamat === ''">
                                Ds. {{ item.t_extra.lokasi_pemohon.desa_name }}, Kec. {{ item.t_extra.lokasi_pemohon.kecamatan_name }}
                              </span>

                              <span v-else>
                                {{ item.t_extra.lokasi_pemohon.alamat }}
                              </span>
                            </div>

                            <v-card
                              flat
                              color="#FFFFFF"
                              class="border-radius">
                              <div
                                class="body-2 font-weight-bold d-flex align-center pa-4">
                                <div>
                                  <!-- <div
                                    class="caption"
                                    style="opacity: 60%;">
                                    Tanggal
                                  </div> -->

                                  <!-- <div>
                                    <span v-if="item.t_is_schedule">
                                      {{ item.t_start_date | date }}
                                    </span>

                                    <span v-else>
                                      Tidak Dijadwalkan
                                    </span>
                                  </div> -->

                                  <div>
                                    <v-chip
                                      small
                                      :color="item.t_extra.type_topic === 'BERUSAHA' ? '#00800014' : '#ff000014'"
                                      class="caption font-weight-bold px-4"
                                      :class="item.t_extra.type_topic === 'BERUSAHA' ? 'green--text' : 'red--text'">
                                      {{ item.t_extra.type_topic }}
                                    </v-chip>
                                  </div>

                                </div>

                                <v-spacer />

                                <!-- <div>
                                  <div
                                    class="caption"
                                    style="opacity: 60%;">
                                    Anggota
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <v-icon color="black" small>mdi-account-multiple-outline</v-icon>
                                    
                                    <span
                                      class="ml-1">
                                      {{ item.participant.length }}
                                    </span>
                                  </div>
                                </div> -->

                                <div
                                  class="d-flex align-center">
                                  <div
                                    class="d-flex align-center"
                                    style="position: relative;">
                                    <v-avatar
                                      v-for="(item_user, index_user) in item.participant"
                                      :key="index_user"
                                      v-show="index_user < 3"
                                      size="22"
                                      style="border: 2px solid #FFF;"
                                      :style="index_user === 2 ? 'position: absolute; right: 36px; z-index: 2;' : 
                                              index_user === 1 ? 'position: absolute; right: 18px; z-index: 1;' : 
                                              'position: absolute; right: 0px;'">
                                      <v-img 
                                        width="100%"
                                        height="100%"
                                        alt="rapatfpr" 
                                        :src="item_user.image === '' || item_user.image === null ? require('@/assets/image/member_default.jpg') : item_user.image"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular 
                                              size="20"
                                              indeterminate 
                                              color="#2391d0">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>
                                  </div>

                                  <div v-if="item.participant.length > 3"
                                    class="caption black--text ml-1"
                                    style="opacity: 60%;">
                                    +{{ item.participant.length - 3 }}
                                  </div>
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        list: {
          user: [],
          master: [],
          topic: []
        },
        pagination: {
          user: {},
          master: {},
          topic: {}
        },
        process: {
          user: false,
          master: false,
          topic: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchUser()
        this.fetchMaster()
        this.fetchTopic()
      },
      async fetchUser () {
        this.process.user = true

        let params = {
          limit: 3
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.user = false
            
            this.list.user = response.data
            this.pagination.user = response.pagination
          } 
        })
      },
      async fetchMaster () {
        this.process.master = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master/template_topic_data`)
        .then((response) => {
          if (response.code === 200) {
            this.process.master = false
            
            this.list.master = response.data
            this.pagination.master = response.pagination
          } 
        })
      },
      async fetchTopic () {
        this.process.topic = true

        let params = {
          limit: 3,
          t_status: 'OPEN'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/topic`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.topic = false
            
            this.list.topic = response.data
            this.pagination.topic = response.pagination
          } 
        })
      }
    }
  }
</script>