<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="py-0"
              style="height: calc(100vh - 350px); 
                  overflow-y: auto;">
              <v-btn
                @click="sheet.form = true; reset_user();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mb-6">
                <div
                  class="btn_auth_in d-flex align-center justify-center">
                  <div>
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="rapatfpr" 
                      :src="require('@/assets/icon/add_2.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Anggota
                  </div>
                </div>
              </v-btn>

              <v-list
                dense
                two-line
                class="pa-0"
                color="transparent">
                <v-list-item-group
                  v-model="form.user_ids"
                  multiple
                  color="transparent">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list.master"
                    :key="index"
                    :loading="process.load"
                    type="list-item-avatar-two-line">
                    <div>
                      <template v-if="!process.load && list.master.length > 0">
                        <v-list-item
                          :value="item.id"
                          class="pa-0">
                          <template v-slot:default="{ active, }">
                            <v-list-item-avatar
                              size="40"
                              class="my-3 mr-3"
                              style="border: 2px solid #FFFFFF;">
                              <v-img 
                                width="100%"
                                height="100%"
                                alt="rapatfpr" 
                                :src="item.image === '' ? require('@/assets/image/member_default.jpg') : item.image"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold black--text line-text-first">
                                {{ item.fullname }}
                              </v-list-item-title>
                              
                              <v-list-item-subtitle
                                class="caption font-weight-light">
                                {{ item.nik }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="#2391d0">
                              </v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </div>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>
              
              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="rapatfpr" 
                      :src="require('@/assets/icon/participant.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Lengkapi data Anggota yang akan mendiskusikan Topik, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="goBack()"
                      :disabled="process.form"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-arrow-left-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Kembali
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <v-card-title 
              @click="sheet.form = false"
              class="cursor-pointer pa-4"
              style="background: #FFFFFF40; 
                    border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;">
              <v-icon 

                color="#2391d0">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 color-default">
                Anggota
              </span>
            </v-card-title>

            <v-card
              flat
              color="transparent"
              style="margin-top: 57px;">
              <v-card-text
                class="pa-4"
                style="height: calc(100vh - 132px); 
                    overflow-y: auto;">
                <ValidationObserver 
                  ref="form_user">
                  <v-form>
                    <div
                      class="mb-6">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Gambar Profil
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <uploadfolder 
                        v-model="upload.folder">
                        <div 
                          slot="activator" 
                          class="cursor-pointer">
                          <v-card 
                            flat
                            width="175"
                            height="175"
                            color="transparent">
                            <div
                              class="d-flex align-center justify-center"
                              style="
                                  width: 175px;
                                  height: 175px;
                                  border-radius: 15px;
                                  border: 2px solid #0000000d;
                                  border-style: dashed;">
                              <v-img v-if="!process.image && form_user.image !== ''"
                                contain
                                alt="kominfo" 
                                width="175"
                                height="171"
                                :src="form_user.image"
                                class="ma-auto d-flex align-center text-center"
                                style="border-radius: 15px;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>

                                <v-icon 
                                  size="50" 
                                  color="grey">
                                  mdi-cloud-upload-outline
                                </v-icon>
                              </v-img>

                              <v-icon v-if="!process.image && form_user.image === ''"
                                size="50" 
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>

                              <v-progress-circular v-if="process.image"
                                indeterminate
                                color="#2391d0">
                              </v-progress-circular>
                            </div>
                          </v-card>
                        </div>
                      </uploadfolder>
                    </div>
                      
                    <ValidationProvider 
                      name="fullname" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Lengkap
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_user"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        placeholder="Contoh. Firmansyah Abdul Rafi"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form_user.fullname"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="nik" 
                      rules="required|min:16|max:16"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        NIK
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_user"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        min="0"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        placeholder="Contoh. 3310026XXXXXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form_user.nik"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="phone" 
                      rules="required|min:8|max:13"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Telepon
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_user"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        min="0"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        placeholder="Contoh. 081332XXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form_user.phone"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="email" 
                      rules="required|email"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Email
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_user"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        placeholder="Contoh. XXXX@gmail.com"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form_user.email"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="password" 
                      rules="required|min:5|max:20" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_user"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        placeholder="Contoh. *****"
                        :type="show ? 'text' : 'password'"
                        prepend-inner-icon="mdi-lock-outline"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        :counter="20"

                        v-model="form_user.password"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-3 mb-2 d-flex align-center">
                  <div
                    class="error--text">
                    <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                    
                    <span
                      class="ml-1">
                      {{ message.error }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      @click="save_user"
                      :disabled="process.form"
                      :loading="process.form"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="sheet.form = false; reset_user();"
                      :disabled="process.form"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        show: false,
        sheet: {
          form: false
        },
        list: {
          data: [],
          master: []
        },
        pagination: {},
        upload: {
          folder: null
        },
        form: {
          topic_id: '',
          user_ids: []
        },
        form_user: {
          id: '',
          fullname: '',
          nik: '',
          email: '',
          phone: '',
          image: '',
          password: ''
        },
        process: {
          load: false,
          form: false,
          image: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_user.image = this.upload.folder.data.full.file_url
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchData()
    },
    methods: {
      goBack () {
        let url_to = this.$route.params.id === undefined ? '/forum/form' : `/forum/form/${this.$route.params.id}`

        this.$emit('step', 3)
        this.$router.push({ path: url_to, query: { step: 3 }})
      },
      async fetchData () {
        this.process.load = true

        let params = {
          p_topic_id: this.$route.params.id,
          topic_id: this.$route.params.id,
          limit: 100000
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/participant`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.list.data = response.data

            this.fetchMaster()
          } 
        })
      },
      async fetchMaster () {
        let params = {
          limit: 100000
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/user`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list.master = response.data
            this.pagination = response.pagination

            this.reset()
          } 
        })
      },
      reset () {
        this.form = {
          topic_id: this.$route.params.id,
          user_ids: []
        }

        if (this.list.data.length > 0) {
          this.list.data.map(obj => {
            this.form.user_ids.push(obj.p_user_id)
          })
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''
        
        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/discussion/participant/add`, this.form)
        .then((response) => {

          if (response.code === 200) {
            this.$router.push({ path: '/forum'})
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      // USER
      reset_user (item) {
        if (this.$refs.form_user) this.$refs.form_user.reset()

        this.form_user = {
          id: item !== undefined ? item.id : '',
          fullname: '',
          nik: '',
          email: '',
          phone: '',
          image: '',
          password: ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save_user () {
        this.message.error = ''
        
        const isValid = await this.$refs.form_user.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_user.id === '' ? '/user/create' : '/user/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}${url}`, this.form_user)
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false
              
              this.sheet.form = false

              this.reset_user()

              this.fetchData()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_user.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_user.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>