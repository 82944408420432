<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="py-0"
              style="height: calc(100vh - 350px); 
                  overflow-y: auto;">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <div
                    v-for="(item, index) in form.data_subject"
                    :key="index">
                    <ValidationProvider
                      :name="item.ds_title" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        {{ item.ds_title }}

                        <span v-if="item.ds_title === 'Luas tanah yang disetujui'">
                          (M<sup>2</sup>)
                        </span>
                        
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field v-if="item.ds_title !== 'Jenis peruntukan Pemanfaatan Ruang'"
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#2391d0"

                        :type="item.ds_title === 'Luas tanah yang disetujui' ? 'number' : ''"
                        :onkeypress="item.ds_title === 'Luas tanah yang disetujui' ? 'return event.charCode >= 46 && event.charCode <= 57' : ''"
                        :min="item.ds_title === 'Luas tanah yang disetujui' ? 0 : ''"

                        :suffix="item.ds_title === 'Luas tanah yang disetujui' ? 'M2' : ''"
                        
                        :placeholder="`Contoh. ${item.ds_title}`"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="item.s_title"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>

                      <v-select v-else
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        :placeholder="`Contoh. ${item.ds_title}`"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        
                        :items="pola_ruang"
                        item-text="pr_title"
                        item-value="pr_title"
                        item-color="#2391d0"

                        clearable
                        @click:clear="item.s_title = []"

                        multiple

                        return-object
                        
                        v-model="item.s_title"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        Keterangan
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        rows="4"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="item.s_content !== '' ? 'mdi-check-circle-outline' : ''"

                        v-model="item.s_content"
                        :success="item.s_content !== ''"
                        style="border-radius: 20px;">
                      </v-textarea>
                    </div>
                  </div>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="rapatfpr" 
                      :src="require('@/assets/icon/subject.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Lengkapi data Subjek yang akan di diskusikan Anggota, kemudian klik <b class="font-italic">Selanjutnya</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Selanjutnya
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="goBack()"
                      :disabled="process.form"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-arrow-left-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Kembali
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        pola_ruang: [],
        form: {
          topic_id: '',
          data_subject: []
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetchData()
    },
    methods: {
      goTo () {
        this.$emit('step', 4)
        this.$router.push({ path: `/forum/form/${this.$route.params.id}`, query: { step: 4 }})
      },
      goBack () {
        let url_to = this.$route.params.id === undefined ? '/forum/form' : `/forum/form/${this.$route.params.id}`

        this.$emit('step', 2)
        this.$router.push({ path: url_to, query: { step: 2 }})
      },
      async fetchData () {
        this.process.load = true

        let params = {
          limit: 100000,
          s_topic_id: this.$route.params.id
        }

        this.form = {
          topic_id: '',
          data_subject: []
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/subject`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            let data = response.data

            this.form.topic_id = this.$route.params.id
            
            data.map(obj => {
              this.form.data_subject.push({
                id: obj.id,
                ds_id: obj.s_ds_id,
                ds_title: obj.ds_title,
                s_title: obj.s_title,
                s_content: obj.s_content
              })
            })

            this.fetch_pola_ruang()
          } 
        })
      },
      async fetch_pola_ruang () {
        let params = {
          limit: 100000
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master/pola_ruang`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.pola_ruang = response.data
          } 
        })
      },
      async save () {
        this.message.error = ''
        
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/discussion/subject/create_bulk`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false

              this.$emit('step', 4)
              this.$router.push({ path: `/forum/form/${this.$route.params.id}`, query: { step: 4 }})
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>