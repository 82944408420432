<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat 
            color="transparent">
            <v-card-text 
              class="pb-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                color="#2391d0"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="article">
                    <template>
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          :to="`/forum/${item.id}`"
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-card-text>
                            <div
                              class="caption mb-2 one-line d-flex align-center">
                              <v-icon small>mdi-account-circle-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.t_extra.nama_pemohon === '' ? 'Tidak Diketahui' : item.t_extra.nama_pemohon }}
                              </span>
                            </div>

                            <div
                              class="body-2 black--text line-text-first two-line mb-2">
                              {{ item.t_extra.judul_kbli_pemohon === '' ? 'Tidak Diketahui' : item.t_extra.judul_kbli_pemohon }}
                            </div>

                            <div
                              class="body-2 black--text line-text-first two-line mb-4"
                              style="opacity: 60%;">
                              <!-- {{ item.t_extra.lokasi_pemohon.alamat === '' ? 'Tidak Diketahui' : item.t_extra.lokasi_pemohon.alamat }} -->
                              <span v-if="item.t_extra.lokasi_pemohon.alamat === ''">
                                Ds. {{ item.t_extra.lokasi_pemohon.desa_name }}, Kec. {{ item.t_extra.lokasi_pemohon.kecamatan_name }}
                              </span>

                              <span v-else>
                                {{ item.t_extra.lokasi_pemohon.alamat }}
                              </span>
                            </div>

                            <div
                              class="d-flex aligncenter">
                              <div>
                                <v-chip
                                  :color="item.t_extra.type_topic === 'BERUSAHA' ? '#00800014' : '#ff000014'"
                                  class="caption font-weight-bold px-4"
                                  :class="item.t_extra.type_topic === 'BERUSAHA' ? 'green--text' : 'red--text'">
                                  <!-- <span v-if="item.t_is_schedule">
                                    {{ item.t_start_date | date }}
                                  </span>

                                  <span v-else>
                                    Tidak Dijadwalkan
                                  </span> -->

                                  {{ item.t_extra.type_topic }}
                                </v-chip>
                              </div>

                              <v-spacer />

                              <div
                                class="d-flex align-center">
                                <div
                                  class="d-flex align-center"
                                  style="position: relative;">
                                  <v-avatar
                                    v-for="(item_user, index_user) in item.participant"
                                    :key="index_user"
                                    v-show="index_user < 3"
                                    size="30"
                                    style="border: 2px solid #FFF;"
                                    :style="index_user === 2 ? 'position: absolute; right: 44px; z-index: 2;' : 
                                            index_user === 1 ? 'position: absolute; right: 22px; z-index: 1;' : 
                                            'position: absolute; right: 0px;'">
                                    <v-img 
                                      width="100%"
                                      height="100%"
                                      alt="rapatfpr" 
                                      :src="item_user.image === '' || item_user.image === null ? require('@/assets/image/member_default.jpg') : item_user.image"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            size="20"
                                            indeterminate 
                                            color="#2391d0">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>
                                </div>

                                <div v-if="item.participant.length > 3"
                                  class="caption black--text ml-1"
                                  style="opacity: 60%;">
                                  +{{ item.participant.length - 3 }}
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-card-text v-if="pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center">
                <v-btn
                  small
                  rounded
                  outlined
                  elevation="0"
                  color="#2391d0"
                  class="color-default text-capitalize"
                  @click="limit += 10">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-8"
                margin="my-16"
                size="125"
                message="Riwayat Diskusi" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        search: '',
        list: [],
        pagination: {},
        limit: 10,
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.fetch()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          limit: this.limit,
          search: this.search,
          t_status: 'CLOSE'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/topic`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      }
    }
  }
</script>