<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <!-- <v-dialog v-model="dialog.filter"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#2391d0">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <v-dialog v-model="dialog.detail">
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="rapatfpr"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#2391d0">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold ml-2">
                Admin Rapat FPR
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              <div
                class="mb-2">
                Silahkan pilih aksi dibawah ini:
              </div>
             
             <div>
               <span style="opacity: 60%;">- Jika melihat detail klik</span> <strong class="font-italic">Detail</strong>
             </div>

             <div>
               <span style="opacity: 60%;">- Jika mengubah data klik</span> <strong class="font-italic">Ubah</strong>
             </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                :to="`/forum/${selected.detail.id}`"
                color="#2391d0"
                elevation="0"
                rounded
                class="text-capitalize white--text">
                <v-icon small class="mr-1">mdi-information-outline</v-icon>
                Detail
              </v-btn>

              <v-btn
                :to="`/forum/form/${selected.detail.id}?step=1`"
                color="#2391d0"
                elevation="0"
                outlined
                rounded
                class="text-capitalize color-default">
                <v-icon small class="mr-1">mdi-pencil-circle-outline</v-icon>
                Ubah
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat 
            color="transparent">
            <v-card-text 
              class="pb-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                color="#2391d0"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn v-if="user.level === 'admin'"
                to="/forum/form?step=1"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4">
                <div
                  class="btn_auth_in d-flex align-center justify-center">
                  <div>
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="rapatfpr" 
                      :src="require('@/assets/icon/add.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Buat Topik
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <!-- <div
                class="body-2 font-weight-bold black--text d-flex align-center">
                <div>
                  Filter
                </div>

                <v-spacer />

                <div>
                  <v-icon 
                    @click="dialog.filter = true; fetchStore();"
                    class="cursor-pointer">
                    mdi-filter-variant
                  </v-icon>
                </div>
              </div> -->

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="article">
                    <template>
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          @click="goTo(item)"
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-card-text>
                            <div
                              class="caption mb-2 one-line d-flex align-center">
                              <v-icon small>mdi-account-circle-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.t_extra.nama_pemohon === '' ? 'Tidak Diketahui' : item.t_extra.nama_pemohon }}
                              </span>
                            </div>

                            <div
                              class="body-2 black--text line-text-first two-line mb-2">
                              {{ item.t_extra.judul_kbli_pemohon === '' ? 'Tidak Diketahui' : item.t_extra.judul_kbli_pemohon }}
                            </div>

                            <div
                              class="body-2 black--text line-text-first two-line mb-4"
                              style="opacity: 60%;">
                              <!-- {{ item.t_extra.lokasi_pemohon.alamat === '' ? 'Tidak Diketahui' : item.t_extra.lokasi_pemohon.alamat }} -->
                              <span v-if="item.t_extra.lokasi_pemohon.alamat === ''">
                                Ds. {{ item.t_extra.lokasi_pemohon.desa_name }}, Kec. {{ item.t_extra.lokasi_pemohon.kecamatan_name }}
                              </span>

                              <span v-else>
                                {{ item.t_extra.lokasi_pemohon.alamat }}
                              </span>
                            </div>

                            <div
                              class="d-flex aligncenter">
                              <div>
                                <v-chip
                                  :color="item.t_extra.type_topic === 'BERUSAHA' ? '#00800014' : '#ff000014'"
                                  class="caption font-weight-bold px-4"
                                  :class="item.t_extra.type_topic === 'BERUSAHA' ? 'green--text' : 'red--text'">
                                  <!-- <span v-if="item.t_is_schedule">
                                    {{ item.t_start_date | date }}
                                  </span>

                                  <span v-else>
                                    Tidak Dijadwalkan
                                  </span> -->

                                  {{ item.t_extra.type_topic }}
                                </v-chip>
                              </div>

                              <v-spacer />

                              <div
                                class="d-flex align-center">
                                <div
                                  class="d-flex align-center"
                                  style="position: relative;">
                                  <v-avatar
                                    v-for="(item_user, index_user) in item.participant"
                                    :key="index_user"
                                    v-show="index_user < 3"
                                    size="30"
                                    style="border: 2px solid #FFF;"
                                    :style="index_user === 2 ? 'position: absolute; right: 44px; z-index: 2;' : 
                                            index_user === 1 ? 'position: absolute; right: 22px; z-index: 1;' : 
                                            'position: absolute; right: 0px;'">
                                    <v-img 
                                      width="100%"
                                      height="100%"
                                      alt="rapatfpr" 
                                      :src="item_user.image === '' || item_user.image === null ? require('@/assets/image/member_default.jpg') : item_user.image"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            size="20"
                                            indeterminate 
                                            color="#2391d0">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>
                                </div>

                                <div v-if="item.participant.length > 3"
                                  class="caption black--text ml-1"
                                  style="opacity: 60%;">
                                  +{{ item.participant.length - 3 }}
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <v-card-text v-if="pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center">
                <v-btn
                  small
                  rounded
                  outlined
                  elevation="0"
                  color="#2391d0"
                  class="color-default text-capitalize"
                  @click="limit += 10">
                  Lihat Lebih Banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-8"
                margin="my-16"
                size="125"
                message="Topik" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          detail: false,

          filter: false
        },
        selected: {
          detail: {}
        },
        search: '',
        list: [],
        pagination: {},
        limit: 10,
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/forum',
          title: 'Topik',
          description: 'Topik Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.fetch()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          limit: this.limit,
          search: this.search,
          t_status: 'OPEN'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/topic`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list = response.data
            this.pagination = response.pagination
          } 
        })
      },
      goTo (item) {
        if (this.user.level === 'admin') {
          this.selected.detail = item

          this.dialog.detail = true
        } else {
          this.$router.push({ path: `/forum/${item.id}`})
        }
      }
    }
  }
</script>