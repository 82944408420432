<template>
  <div class="fill-height pb-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent"
            style="position: fixed;
                  top: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card
              flat
              height="56"
              color="#FFFFFF40"
              class="d-flex align-center pl-4 pr-3"
              style="z-index: 9; border-radius: 0px;">
              <div v-if="Object.keys(detail).length > 0">
                <v-menu 
                  v-model="menu_model"
                  :close-on-content-click="false"
                  offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      width="75"
                      v-bind="attrs"
                      v-on="on"
                      class="body-1 font-weight-bold text-capitalize px-0 d-flex align-center">
                      Topik

                      <v-spacer />

                      <v-icon size="18">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  
                  <v-card
                    flat
                    class="border-radius">
                    <v-card-text
                      class="black--text pb-2">
                      <div
                        class="body-2 font-weight-bold mb-1">
                        Topik Diskusi
                      </div>

                      <div
                        class="body-2 line-text-first"
                        style="opacity: 70%;">
                        Silahkan pilih topik dibawah ini:
                      </div>

                      <v-divider
                        class="mt-4 mb-2"
                        style="border-top: 3px solid #dbe1e3; border-radius: 50px;" />

                      <v-list
                        dense
                        class="pa-0"
                        style="max-height: 300px; overflow-y: auto;">
                        <v-list-item
                          v-for="(item, index) in list.topic"
                          :key="index"
                          class="pa-0"
                          @click="selected.topic = item.id">
                          <v-list-item-icon
                            class="mr-2">
                            <v-icon 
                              size="22" 
                              :color="selected.topic !== item.id ? '' : '#2391d0'">
                              {{ selected.topic !== item.id ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked' }}
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 black--text">
                              {{ item.t_title }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>

                    <v-card-actions
                      class="pa-4 pt-2">
                      <!-- <v-btn
                        @click="sheet.form_topic = true; reset_topic();"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_auth">
                        <div
                          class="btn_auth_in">
                          Masukkan Data
                        </div>
                      </v-btn> -->

                      <v-btn
                        @click="menu_model = false; fetch();"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_auth">
                        <div
                          class="btn_auth_in">
                          Mulai Diskusi
                        </div>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>

              <v-spacer />

              <v-btn
                icon
                width="32"
                height="32">
                <v-icon size="22" color="#00000066">mdi-magnify</v-icon>
              </v-btn>

              <v-btn
                icon
                width="32"
                height="32">
                <v-icon size="22" color="#00000066">mdi-filter-variant</v-icon>
              </v-btn>
            </v-card>

            <v-card
              flat
              color="#FFFFFF40"
              style="z-index: 9; border-radius: 0px 0px 15px 15px;">
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide 
                    v-for="(item, index) in menu" 
                    :key="index" 
                    class="py-3" 
                    :class="index === 0 ? 'pl-4 pr-3' : index < menu.length - 1 ? 'pr-3' : 'pr-4'"
                    style="max-width: 105px;">
                    <div>
                      <v-btn
                        small
                        rounded
                        width="80"
                        elevation="0"
                        @click="selected.menu = item.value"
                        class="body-2 text-capitalize"
                        :color="selected.menu === item.value ? '#2391d0' : '#00000014'"
                        :class="selected.menu === item.value ? 'white--text font-weight-bold' : 'grey--text'">
                        {{ item.title }}
                      </v-btn>
        
                      <div v-if="selected.menu === item.value"
                        class="text-center">
                        <v-icon
                          color="#2391d0"
                          size="10">
                          mdi-circle
                        </v-icon>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </v-card>
          </v-card>

          <v-card
            flat
            color="transparent"
            style="margin-top: 130px;">
            <v-card-text
              ref="messages"
              style="height: calc(100vh - 255px); 
                  overflow-y: auto;">
              <!-- DETAIL -->
              <div v-if="selected.menu === 1">
                <v-skeleton-loader
                  :loading="process.topic"
                  type="article, list-item-three-line, list-item-three-line, list-item-three-line, image">
                  <div>
                    <div v-if="!process.topic && Object.keys(detail).length > 0">
                      <v-card
                        flat
                        color="transparent">
                        <div
                          class="body-1 font-weight-bold line-text-first my-1">
                          {{ detail.t_title }}
                        </div>

                        <div
                          class="body-2 mb-6"
                          style="opacity: 70%;">
                          {{ detail.t_content }}
                        </div>

                        <v-img 
                          width="150"
                          height="150"
                          alt="rapatfpr" 
                          :src="detail.t_image"
                          class="border-radius">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>

                        <div v-if="detail.t_is_schedule"
                          class="body-2 mt-6 mb-2">
                          Dijadwalkan Pada:
                        </div>

                        <div
                          class="d-flex align-center mb-6">
                          <div>
                            <div
                              class="caption line-text-second"
                              style="opacity: 60%;">
                              Tanggal Mulai
                            </div>

                            <div
                              class="body-2">
                              {{ detail.t_start_date | date }}
                            </div>
                          </div>

                          <v-spacer />

                          <div>
                            <div
                              class="caption line-text-second"
                              style="opacity: 60%;">
                              Tanggal Selesai
                            </div>

                            <div
                              class="body-2">
                              {{ detail.t_end_date | date }}
                            </div>
                          </div>
                        </div>
                      </v-card>

                      <div
                        class="body-2 font-weight-bold black--text mb-1">
                        {{ detail.t_extra.type_topic }}
                      </div>

                      <v-list
                        dense
                        class="pa-0 mb-6"
                        color="transparent">
                        <v-list-item
                          v-for="(item, index) in detail.t_extra.data_topic"
                          :key="index"
                          class="pa-0">
                          <v-list-item-icon
                            class="mr-0">
                            <v-icon size="14" color="#2391d0">mdi-rhombus</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 black--text line-text-second">
                              {{ item.td_title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2"
                              style="opacity: 70%;">
                              {{ item.td_form === '' ? '-' : item.td_form }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <div
                        class="body-2 font-weight-bold black--text mb-3">
                        Lampiran
                      </div>

                      <div
                        v-for="(item, index) in process.topic ? 6 : list.attachment"
                        :key="index">
                        <v-card
                          flat
                          color="#FFFFFF40"
                          class="border-radius"
                          :class="index < list.attachment.length - 1 ? 'mb-4' : ''">
                          <v-card-text
                            class="pb-0">
                            <div
                              class="body-2 font-weight-bold line-text-first black--text mb-3">
                              <span
                                class="two-line">
                                {{ item.title }}
                              </span>
                            </div>

                            <div
                              class="body-2 line-text-first">
                              <span
                                class="three-line">
                                Untuk melihat lampiran topik atau data file, silahkan klik tombol dibawah ini:
                              </span>
                            </div>
                          </v-card-text>

                          <v-card-actions
                            class="d-flex align-center pa-4">
                            <v-btn
                              @click="sheet.attachment = true; selected.attachment = item;"
                              x-small
                              rounded
                              color="#2391d033"
                              elevation="0"
                              class="text-capitalize color-default">
                              Lihat File
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </v-skeleton-loader>
              </div>

              <!-- PEMOHON -->
              <div v-if="selected.menu === 2">
                <v-skeleton-loader
                  :loading="process.topic"
                  type="article, list-item-three-line, article, list-item-three-line, list-item-three-line">
                  <div>
                    <v-card v-if="!process.topic && Object.keys(detail).length > 0"
                      flat
                      color="transparent">
                      <div
                        class="caption my-1"
                        style="opacity: 70%;">
                        Nama Pemohon
                      </div>

                      <div
                        class="body-2 font-weight-bold mb-4">
                        {{ detail.t_extra.nama_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        NPWP
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.npwp_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Alamat Pemohon
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.alamat_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Nomor Telepon
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.phone_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Email
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.email_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Kode KBLI
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.kode_kbli_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Judul KBLI
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.judul_kbli_pemohon }}
                      </div>

                      <div v-if="detail.t_extra.type_topic === 'BERUSAHA'">
                        <div
                          class="caption mb-1"
                          style="opacity: 70%;">
                          Skala Usaha
                        </div>

                        <div
                          class="body-2 black-text">
                          {{ detail.t_extra.skala_usaha_pemohon }}
                        </div>
                      </div>

                      <v-divider
                        class="my-4"
                        style="border-top: 3px solid #dbe1e3; border-radius: 50px;" />

                      <div
                        class="body-2 font-weight-bold black--text mb-3">
                        Lokasi Usaha
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Alamat
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.lokasi_pemohon.alamat }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Desa/Kelurahan
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.lokasi_pemohon.desa_name }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Kecamatan
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.lokasi_pemohon.kecamatan_name }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Kabupaten
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.lokasi_pemohon.kabupaten_name }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Provinsi
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.lokasi_pemohon.provinsi_name }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Luas Tanah
                      </div>

                      <div
                        class="body-2 black-text mb-4">
                        {{ detail.t_extra.luas_tanah_pemohon }}
                      </div>

                      <div
                        class="caption mb-1"
                        style="opacity: 70%;">
                        Rencana Teknis
                      </div>

                      <div
                        class="body-2 black-text">
                        {{ detail.t_extra.rencana_teknis_pemohon }}
                      </div>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </div>

              <!-- SUBJECT -->
              <div v-if="selected.menu === 3">
                <v-list
                  dense
                  two-line
                  class="pa-0"
                  color="transparent">
                  <v-skeleton-loader
                    v-for="(item, index) in process.topic ? 6 : list.subject"
                    :key="index"
                    :loading="process.topic"
                    type="list-item-three-line">
                    <div>
                      <v-list-item
                        class="px-0"
                        style="position: relative;">
                        <v-list-item-avatar
                          size="14"
                          class="mr-3"
                          style="position: absolute; top: 4px;">
                          <v-icon size="14" color="#2391d0">mdi-rhombus</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content
                          class="pa-0 ml-7 py-4"
                          :style="index < list.subject.length - 1 ? 'border-bottom: 1px dashed #bbb;' : ''">
                          <div
                            class="body-2 line-text-first black--text one-line d-flex align-center">
                            <v-icon size="14">mdi-paperclip</v-icon>
                            
                            <span
                              class="one-line ml-1">
                              {{ item.ds_title }}
                            </span>
                          </div>

                          <div
                            class="body-2 line-text-first font-weight-bold black--text one-line">
                            {{ item.s_title }}
                          </div>

                          <div
                            class="body-2 line-text-first two-line"
                            style="opacity: 70%;">
                            {{ item.s_content === '' ? 'Keterangan tidak ada' : item.s_content }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-skeleton-loader>
                </v-list>
              </div>

              <!-- PARTICIPANT -->
              <div v-if="selected.menu === 4">
                <v-list
                  dense
                  two-line
                  class="pa-0"
                  color="transparent">
                  <v-skeleton-loader
                    v-for="(item, index) in process.topic ? 7 : list.participant"
                    :key="index"
                    :loading="process.topic"
                    type="list-item-avatar-two-line">
                    <div>
                      <v-list-item
                        class="px-0">
                        <v-list-item-avatar
                          size="40"
                          class="my-3 mr-3"
                          style="border: 2px solid #FFFFFF;">
                          <v-img 
                            width="100%"
                            height="100%"
                            alt="rapatfpr" 
                            :src="item.image"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content
                          class="pa-0 py-4"
                          :style="index < list.participant.length - 1 ? 'border-bottom: 1px dashed #bbb;' : ''">
                          <v-list-item-title
                            class="body-2 font-weight-bold line-text-first">
                            {{ item.fullname }}
                          </v-list-item-title>
                          
                          <v-list-item-subtitle
                            class="caption font-weight-light">
                            Anggota
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-skeleton-loader>
                </v-list>
              </div>

              <!-- CHAT -->
              <div v-if="selected.menu === 5">
                <v-skeleton-loader
                  v-for="(item, index) in process.message ? 6 : list.message"
                  :key="index"
                  :loading="process.message"
                  type="list-item-avatar-three-line">
                  <div>
                    <v-row
                      align="start">
                      <v-col
                        cols="2">
                        <v-avatar
                          size="40"
                          style="border: 2px solid #FFFFFF;">
                          <v-img 
                            width="100%"
                            height="100%"
                            class="mx-auto"
                            alt="rapatfpr"
                            :src="item.m_user_image === undefined || item.m_user_image === '' ? require('@/assets/image/member_default.jpg') : item.m_user_image">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  color="#2391d0">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-col>

                      <v-col
                        cols="10"
                        class="pl-0">
                        <v-card
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-card-text
                            class="pa-3">
                            <div
                              class="caption font-weight-bold black--text">
                              {{ item.m_user_fullname }}
                            </div>

                            <div v-if="item.m_type === 'text'"
                              class="body-2 line-text-first mt-1">
                              {{ item.m_message }}
                            </div>

                            <div v-if="item.m_type === 'image'"
                              class="mt-2">
                              <v-img 
                                width="100%"
                                height="auto"
                                alt="rapatfpr"
                                class="mx-auto border-radius"
                                :src="item.m_message">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div v-if="item.m_type === 'document'"
                              class="mt-2">
                              <iframe 
                                :src="`${item.m_message}#toolbar=0`" 
                                allowfullscreen
                                frameborder="0" 
                                width="100%"
                                height="397"
                                style="border-radius: 15px; border: 2px solid #323639;">
                              </iframe>
                            </div>
                          </v-card-text>
                        </v-card>

                        <div
                          class="caption font-weight-bold d-flex align-center py-2 px-3">
                          <div>
                            {{ item.m_create_datetime | ago }}
                          </div>

                          <div
                            class="mx-3">
                            Balas
                          </div>

                          <v-spacer />

                          <div>
                            <v-icon small color="#00000066">mdi-heart-outline</v-icon>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-skeleton-loader>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF40"
            min-height="100"
            style="border-radius: 15px 15px 0px 0px;
                  position: fixed;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text>
              <v-alert
                type="error" 
                text
                dense
                prominent
                v-show="message.error.length > 0"
                v-html="message.error">
              </v-alert>

              <div>
                <ValidationObserver 
                  ref="form">
                  <ValidationProvider 
                    name="message" 
                    rules="required" 
                    v-slot="{ errors }">
                    <v-text-field
                      v-on:keyup.enter="addMessage"
                      :disabled="process.form || process.image"
                      rounded
                      color="#2391d0"
                      single-line 
                      hide-details
                      filled
                      dense
                      :placeholder="process.image ? 'Sedang mengupload file . . .' : 'Tuliskan pesan anda disini . . .'"

                      @focus="toSlide()"

                      v-model="form.message"
                      :error-messages="process.image ? '' : errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0 d-flex align-center">
                      <v-btn
                        @click="sheet.option = true; form.type = 'image';"
                        :disabled="process.image"
                        fab
                        color="#f0f0f0"
                        width="40"
                        height="40"
                        elevation="0">
                        <v-img
                          width="25"
                          height="25"
                          contain
                          alt="kominfo"
                          :src="require('@/assets/icon/upload_image.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                size="25"
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-btn>
                      
                      <upload-document 
                        v-model="upload.document">
                        <div slot="activator">
                          <v-btn
                            @click="form.type = 'document'"
                            :disabled="process.doc"
                            fab
                            color="#f0f0f0"
                            width="40"
                            height="40"
                            elevation="0"
                            class="mx-3">
                            <v-img
                              width="25"
                              height="25"
                              contain
                              alt="kominfo"
                              :src="require('@/assets/icon/upload_folder.png')">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    size="25"
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-btn>
                        </div>
                      </upload-document>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0 text-right">
                      <v-btn
                        @click="addMessage()"
                        :disabled="process.form || process.image"
                        :loading="process.form"
                        fab
                        color="#f0f0f0"
                        width="40"
                        height="40"
                        elevation="0">
                        <v-img
                          width="25"
                          height="25"
                          contain
                          alt="kominfo"
                          :src="require('@/assets/icon/send_chat.png')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                size="25"
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-btn>
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </div>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.form_topic"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <v-card-title 
              @click="sheet.form_topic = false"
              class="cursor-pointer pa-4"
              style="background: #FFFFFF40; 
                    border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;">
              <v-icon 

                color="#2391d0">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 color-default">
                Kelengkapan Data
              </span>
            </v-card-title>

            <v-card
              flat
              color="transparent"
              style="margin-top: 57px;">
              <v-card-text
                class="pa-4"
                style="height: calc(100vh - 132px); 
                    overflow-y: auto;">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <div
                      v-for="(item, index) in form_topic.extra.data_topic"
                      :key="index">
                      <div>
                        <div class="body-2 mb-2">
                          {{ item.td_title }}
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>
                        
                        <v-text-field
                          v-on:keyup.enter="save_topic"
                          filled
                          rounded
                          dense
                          color="#2391d0"
                          :placeholder="`Contoh. ${item.td_title}`"
                          :append-icon="item.td_form !== '' ? 'mdi-check-circle-outline' : ''"

                          v-model="item.td_form"
                          :success="item.td_form !== ''">
                        </v-text-field>
                      </div>
                    </div>
                  </v-form>
                </ValidationObserver>

                <div v-if="message.error.length > 0"
                  class="text-center mt-3 mb-2 d-flex align-center">
                  <div
                    class="error--text">
                    <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                    
                    <span
                      class="ml-1">
                      {{ message.error }}
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      @click="save_topic"
                      :disabled="process.form_topic"
                      :loading="process.form_topic"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="sheet.form_topic = false; reset_topic();"
                      :disabled="process.form_topic"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.attachment"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <v-card-title 
              @click="sheet.attachment = false"
              class="cursor-pointer pa-4"
              style="background: #FFFFFF40; 
                    border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;">
              <v-icon 

                color="#2391d0">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 color-default">
                Lampiran
              </span>
            </v-card-title>

            <v-card
              flat
              color="transparent"
              style="margin-top: 57px;">
              <v-card-text
                class="pa-4"
                style="height: calc(100vh - 48px); 
                    overflow-y: auto;">
                <div
                  class="body-2 font-weight-bold black--text mb-1">
                  {{ selected.attachment.title }}
                </div>

                <v-row>
                  <v-col
                    v-for="(item, index) in selected.attachment.data"
                    :key="index"
                    cols="6">
                    <v-card 
                      flat
                      width="100%"
                      height="225"
                      color="transparent">
                      <div
                        class="d-flex align-center justify-center"
                        style="
                            width: 100%;
                            height: 225px;
                            border-radius: 15px;
                            border: 2px solid #0000000d;
                            border-style: dashed;">
                        <v-img 
                          contain
                          alt="kominfo" 
                          width="100%"
                          height="221"
                          :src="item.file_url"
                          class="ma-auto"
                          style="border-radius: 15px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.option"
          hide-overlay>
          <v-card
            flat
            color="#fff"
            class="d-flex align-center"
            style="border-radius: 30px 30px 0px 0px;">
            <v-card-text
              class="pa-3">
              <v-row 
                align="center" 
                justify="center">
                <!-- CAMERA -->
                <v-col cols="4" class="text-center">
                  <div @click="sheet.upload = true; selected.type = 'camera';"
                    class="cursor-pointer">
                    <v-avatar 
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="rapatfpr" 
                        :src="require('@/assets/image/camera.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div 
                      class="font-weight-bold black--text mt-2">
                      Kamera
                    </div>
                  </div>
                </v-col>

                <v-col 
                  cols="4" 
                  class="text-center px-0">
                  <div 
                    class="d-flex align-center">
                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div 
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!-- FOLDER -->
                <v-col 
                  cols="4" 
                  class="text-center">
                  <upload-folder 
                    v-model="upload.folder">
                    <div slot="activator">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="rapatfpr" 
                          :src="require('@/assets/image/folder.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div 
                        class="font-weight-bold black--text mt-2">
                        Galeri
                      </div>
                    </div>
                  </upload-folder>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.upload"
          hide-overlay
          persistent>
          <upload-camera v-if="selected.type === 'camera'"
            :sheet="sheet.upload"
            @frame="sheet.upload = $event"
            @input="addCamera($event)"
            @close="sheet.upload = $event; selected.type = '';" />
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          option: false,
          upload: false,
          form_topic: false,
          attachment: false
        },
        search: '',
        selected: {
          topic: null,
          menu: 1,
          type: '',
          attachment: {}
        },
        menu_model: false,
        menu: [
          {
            title: 'Detail',
            value: 1
          },
          {
            title: 'Pemohon',
            value: 2
          },
          {
            title: 'Subjek',
            value: 3
          },
          {
            title: 'Anggota',
            value: 4
          },
          {
            title: 'Obrolan',
            value: 5
          }
        ],
        detail: {},
        list: {
          topic: [],
          message: [
            {
              name: 'John Doe',
              image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-zsO-WB5sqXt2_4XGhgNqeecBwQ2dm2dTFcV4NBR0hBIK9nlKRuIz8HUwEo-eEteJBm4&usqp=CAU',
              desc: 'Izin usaha ternak ikan yang di ajukan oleh Bp. Abdul dari desa kradenan',
              datetime: '2022-12-28 08:00'
            },
            {
              name: 'Cintya',
              image: 'https://media.istockphoto.com/id/1154642632/photo/close-up-portrait-of-brunette-woman.jpg?s=612x612&w=0&k=20&c=d8W_C2D-2rXlnkyl8EirpHGf-GpM62gBjpDoNryy98U=',
              desc: 'Ini gimana ya',
              datetime: '2022-12-29 10:00'
            },
            {
              name: 'Alex',
              image: 'https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg',
              desc: 'Silahkan di diskusikan bersama',
              datetime: '2022-12-29 10:30'
            },
            {
              name: 'Albert',
              image: 'https://cdn.pixabay.com/photo/2016/02/25/18/26/man-1222621_1280.jpg',
              desc: 'Ini dokumen ada yang kurang ya ?',
              datetime: '2022-12-30 14:00'
            },
            {
              name: 'Kane',
              image: 'https://newprofilepic2.photo-cdn.net//assets/images/article/profile.jpg',
              desc: 'Siap bapak',
              datetime: '2022-12-30 16:10'
            }
          ],
          attachment: [],
          subject: [],
          participant: []
        },
        pagination: {
          topic: {},
          message: {}
        },
        upload: {
          camera: null,
          folder: null,
          document: null
        },
        form: {
          topic_id: '',
          subject_id: '',
          message: '',
          type: 'text'
        },
        form_topic: {
          id: '',
          title: '',
          content: '',
          image: '',
          lampiran: [],
          subject: [],
          participant: [],
          extra: {
            type_topic: '',
            data_topic: []
          },
          is_schedule: 0,
          start_date: '',
          end_date: ''
        },
        process: {
          load: false,
          message: false,
          topic: false,
          form: false,
          image: false,
          doc: false,

          // TOPIC DATA
          form_topic: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/forum/:id',
          title: 'Detail Topik',
          description: 'Detail Topik Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadCamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      UploadDocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue')
    },
    watch: {
      'selected.menu': function (val) {
        if (val === 1) {
          this.fetchMessage()
        } else {
          this.fetchTopicDetail()
        }
      },
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD IMAGE
              this.sheet.option = false

              this.form.message = this.upload.folder.data.full.file_url

              this.addMessage()
            }
          }
        },
        deep: true
      },
      'upload.document': {
        handler: function () {
          if (this.upload.document) {
            this.process.doc = this.upload.document.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.message = this.upload.document.data.file_url

              this.addMessage()
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.selected.topic = parseInt(this.$route.params.id)

      this.fetch()
    },
    methods: {
      scrollToEnd: function () {
        let content = this.$refs.messages
        if (content !== undefined) {
          content.scrollTop = content.scrollHeight
        }
      },
      fetch () {
        this.fetchTopic()
        this.fetchTopicDetail()
        this.fetchMessage()
      },
      async fetchTopic () {
        this.process.load = true

        let params = {
          limit: 100000,
          t_status: 'OPEN'
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/topic`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false
            
            this.list.topic = response.data
            this.pagination.topic = response.pagination
          } 
        })
      },
      async fetchMessage (type) {
        this.process.message = type === 'send' ? false : true

        let params = {
          topic_id: this.selected.topic
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/message`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.message = false
            
            this.list.message = response.data
            this.pagination.message = response.pagination

            this.reset()
          } 
        })
      },
      async fetchTopicDetail () {
        this.process.topic = true

        let params = {
          id: this.selected.topic,
          search: this.search
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/discussion/topic/detail`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.process.topic = false
            
            this.detail = response.data
            this.list.attachment = response.data.t_lampiran
            this.list.subject = response.data.t_subject
            this.list.participant = response.data.t_participant
          } 
        })
      },

      // ADD MESSAGE
      addCamera (e) {
        this.process.image = e.process

        if (!e.process) {
          this.form.message = e.data.full.file_url

          this.sheet.option = false

          this.addMessage()
        } 
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          topic_id: this.selected.topic,
          subject_id: '',
          message: '',
          type: 'text'
        }

        this.process.form = false

        this.message.error = ''
      },
      async addMessage () {
        const isValid = this.form.type === 'text' ? await this.$refs.form.validate() : true
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/discussion/message/create`, this.form)
          .then((response) => {
            this.process.form = false

            if (response.code === 200) {
              this.fetchMessage('send')

              this.$nextTick(() => this.scrollToEnd())

            } else {
              this.message.error = response.message
            }
          })
        }
      },

      // TOPIC DATA
      reset_topic () {
        if (this.$refs.form_topic) this.$refs.form_topic.reset()

        this.form_topic = {
          id: this.detail.t_id,
          title: this.detail.t_title,
          content: this.detail.t_content,
          image: this.detail.t_image,
          lampiran: this.detail.t_lampiran,
          subject: this.detail.t_subject,
          participant: this.detail.t_participant,
          extra: this.detail.t_extra,
          is_schedule: this.detail.t_is_schedule,
          start_date: this.detail.t_start_date,
          end_date: this.detail.t_end_date
        }

        this.process.form_topic = false

        this.message.error = ''
      },
      async save_topic () {
        this.process.form_topic = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.PREFIX_PROXY}/discussion/topic/update`, this.form_topic)
        .then((response) => {

          if (response.code === 200) {
            this.process.form_topic = false

            this.sheet.form_topic = false

            this.fetchTopicDetail()
          } else {
            this.process.form_topic = false
            this.message.error = response.message
          }
        })
      },

      // SLIDE
      toSlide () {
        this.$refs.swiper.$swiper.slideTo(4, 500, false)
        this.selected.menu = 5
      }
    }
  }
</script>