<template>
  <v-card 
    flat 
    class="overflow-hidden"
    style="border-radius: 0px;">
    <v-app-bar 
      v-if="!loader && 
            $route.path !== '/' &&
            $route.path !== '/auth/forgot' &&
            $route.path !== '/auth/check' &&
            $route.path !== '/auth/success' &&
            $route.name !== 'auth-reset-slug' &&
            $route.name !== 'forum-id' &&
            
            $route.path !== '/admin'"
      app
      absolute
      color="#eaf5f7"
      elevation="0"
      hide-on-scroll
      :prominent="false"
      scroll-target="#scrolling-techniques-4"
      style="z-index: 9; border-radius: 0px 0px 15px 15px;">
      <div
        class="d-flex align-center">
        <v-avatar
          size="36"
          style="border: 2px solid #FFF;">
          <v-img 
            width="100%"
            height="100%"
            alt="rapatfpr" 
            :src="user.image"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  color="#2391d0">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>

        <div
          class="ml-2">
          <div
            style="font-size: 11px; opacity: 70%;">
            Selamat {{ isDay }},
          </div>

          <div
            class="body-2 font-weight-bold one-line text-capitalize">
            {{ user.fullname }}
          </div>
        </div>
      </div>

      <v-spacer />

      <v-btn
        icon 
        width="32"
        height="32"
        style="position: absolute; right: 24px;"
        @click="sheet = true">
        <v-img 
          contain
          width="22"
          height="22"
          alt="rapatfpr" 
          :src="require('@/assets/icon/bell.png')"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                color="#2391d0">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-btn>
    </v-app-bar>

    <v-sheet 
      id="scrolling-techniques-4"
      class="overflow-y-auto"
      max-height="100vh"
      @scroll="onScroll">
      <div
        style="min-height: 100vh;">
        <Loader v-if="loader" />
        
        <div
          class="background">
          <nuxt 
            class="blur" />
        </div>
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="sheet"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        style="border-radius: 0px;">
        <v-card-title 
          @click="sheet = false"
          class="cursor-pointer pa-4"
          style="border-radius: 0px 0px 15px 15px;
                position: absolute;
                width: 100%;
                z-index: 1;
                background: #2391d0;">
          <v-icon color="white">
            mdi-arrow-left-thick
          </v-icon>

          <span
            class="body-1 font-weight-bold text-capitalize white--text ml-1">
            Pemberitahuan
          </span>
        </v-card-title>

       <v-card-text
        style="margin-top: 250px;">
        <v-img 
          width="200"
          height="auto"
          alt="rapatfpr" 
          :src="require('@/assets/image/coming_soon.png')"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                indeterminate 
                color="#2391d0">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <div
          class="body-1 font-weight-light line-text-second text-center mt-8 mb-6">
          Tunggu fitur-fitur menarik dari Rapat FPR, untuk kemudahan kebutuhan Anda.
        </div>

        <div
          class="body-1 font-weight-bold black--text line-text-second text-center">
          - Rapat FPR - 
        </div>
      </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
  import Loader from "~/components/Loader.vue"
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        loader: true,
        sheet: false,
        scroll: true
      }
    },
    components: {
      Loader
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      user() {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      this.loader = false
    },
    beforeCreate() {
      this.loader = true
    },
    created() {
      
    },
    methods: {
      onScroll () {
        const el = document.getElementById('scrolling-techniques-4')
        
        this.scroll = el.scrollTop < window.innerHeight ? true : false

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>