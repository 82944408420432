<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="py-0"
              style="height: calc(100vh - 350px); 
                  overflow-y: auto;">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <!-- <ValidationProvider 
                    name="data_topic" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jenis Topik
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Pola Ruang RTRW"
                      :append-icon="!valid ? '' : 'mdi-check-circle-outline'"
                      
                      :items="list"
                      item-text="td_title"
                      item-value="td_id"
                      item-color="#2391d0"

                      return-object

                      v-model="form.extra.data_topic"
                      :success="valid"
                      :error-messages="errors"
                      required>
                      <template v-slot:item="{item }">
                        <div
                          style="font-size: 0.8125rem; font-weight: 500; line-height: 1rem;">
                          {{ item.td_title }} ({{ item.td_type }})
                        </div>
                      </template>

                      <template v-slot:selection="{ item }">
                        <div
                          style="font-size: 0.8125rem; font-weight: 500; line-height: 1rem;">
                          {{ item.td_title }} ({{ item.td_type }})
                        </div>
                      </template>
                    </v-select>
                  </ValidationProvider> -->
                  
                  <ValidationProvider 
                    name="type_topic" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jenis Topik
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Berusaha"
                      :append-icon="!valid ? '' : 'mdi-check-circle-outline'"
                      
                      :items="[
                        {
                          text: 'Berusaha',
                          value: 'BERUSAHA'
                        },
                        {
                          text: 'Non Berusaha',
                          value: 'NONBERUSAHA'
                        }
                      ]"
                      item-text="text"
                      item-value="value"
                      item-color="#2391d0"

                      v-on:change="form.extra.data_topic = []; fetch(); fetch_pola_ruang();"

                      v-model="form.extra.type_topic"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>
                  
                  <ValidationProvider 
                    name="surat_no" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nomor Surat
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. 1"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.surat_no"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="surat_date" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Bulan & Tahun Surat
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. I.2023"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.surat_date"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <div v-if="form.extra.type_topic !== ''"
                    class="mb-6">
                    <v-expansion-panels
                      flat>
                      <v-expansion-panel
                        class="border-radius"
                        style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                        <v-expansion-panel-header
                          color="white"
                          class="body-2 font-weight-bold border-radius pa-4">
                          Kelengkapan Data

                          <template v-slot:actions>
                            <v-icon>
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                          color="transparent">
                          <v-card
                            flat
                            class="border-radius">
                            <v-card-text
                              class="pt-0">
                              <div
                                v-for="(item, index) in form.extra.data_topic"
                                :key="index">
                                <div>
                                  <ValidationProvider v-if="item.td_title !== 'Pola Ruang RTRW'"
                                    :name="item.td_form" 
                                    rules="required" 
                                    v-slot="{ errors, valid }">
                                    <div class="body-2 mb-2">
                                      <span class="red--text">*</span>
                                      {{ item.td_title }}

                                      <span v-if="item.td_title === 'Kebutuhan Luas Lahan'">
                                        (M<sup>2</sup>)
                                      </span>

                                      <span class="error--text">
                                        (wajib diisi)
                                      </span>
                                    </div>

                                    <v-text-field 
                                      v-on:keyup.enter="save"
                                      filled
                                      rounded
                                      dense
                                      color="#2391d0"

                                      :type="item.td_title === 'Kebutuhan Luas Lahan' ? 'number' : ''"
                                      :onkeypress="item.td_title === 'Kebutuhan Luas Lahan' ? 'return event.charCode >= 46 && event.charCode <= 57' : ''"
                                      :min="item.td_title === 'Kebutuhan Luas Lahan' ? 0 : ''"
                                      
                                      :placeholder="`Contoh. ${item.td_title}`"
                                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                      :suffix="item.td_title === 'Kebutuhan Luas Lahan' ? 'M2' : ''"

                                      v-model="item.td_form"
                                      :success="valid"
                                      :error-messages="errors"
                                      required>
                                    </v-text-field>
                                  </ValidationProvider>

                                  <ValidationProvider v-else
                                    :name="item.td_form" 
                                    rules="required" 
                                    v-slot="{ errors, valid }">
                                    <div class="body-2 mb-2">
                                      <span class="red--text">*</span>
                                      Peruntukan Rencana Tata Ruang
                                      <span class="error--text">
                                        (wajib diisi)
                                      </span>
                                    </div>

                                    <v-select 
                                      v-on:keyup.enter="save"
                                      filled
                                      rounded
                                      dense
                                      color="#2391d0"
                                      :placeholder="`Contoh. ${item.td_title}`"
                                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                                      
                                      :items="pola_ruang"
                                      item-text="pr_title"
                                      item-value="pr_title"
                                      item-color="#2391d0"

                                      clearable
                                      @click:clear="item.td_form = []"

                                      multiple

                                      return-object
                                      
                                      v-model="item.td_form"
                                      :success="valid"
                                      :error-messages="errors"
                                      required>
                                    </v-select>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  
                  <div
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Gambar Utama
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <uploadfolder 
                      v-model="upload.folder">
                      <div 
                        slot="activator" 
                        class="cursor-pointer">
                        <v-card 
                          flat
                          width="200"
                          height="200"
                          color="transparent">
                          <div
                            class="d-flex align-center justify-center"
                            style="
                                width: 200px;
                                height: 200px;
                                border-radius: 15px;
                                border: 2px solid #0000000d;
                                border-style: dashed;">
                            <v-img v-if="!process.image && form.image !== ''"
                              contain
                              alt="kominfo" 
                              width="200"
                              height="194"
                              :src="form.image"
                              class="ma-auto d-flex align-center text-center"
                              style="border-radius: 15px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#2391d0">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <v-icon 
                                size="50" 
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>
                            </v-img>

                            <v-icon v-if="!process.image && form.image === ''"
                              size="50" 
                              color="grey">
                              mdi-cloud-upload-outline
                            </v-icon>

                            <v-progress-circular v-if="process.image"
                              indeterminate
                              color="#2391d0">
                            </v-progress-circular>
                          </div>
                        </v-card>
                      </div>
                    </uploadfolder>
                  </div>
                  
                  <ValidationProvider 
                    name="title" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Judul Topik
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      placeholder="Contoh. Rapat Koordinasi"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.title"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="content" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Keterangan
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      color="#2391d0"
                      rows="4"
                      placeholder="Contoh. Tuliskan keterangan disini . . ."
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.content"
                      :success="valid"
                      :error-messages="errors"
                      required
                      style="border-radius: 20px;">
                    </v-textarea>
                  </ValidationProvider>

                  <div
                    class="mb-6">
                    <v-checkbox
                      v-model="form.is_schedule"
                      color="#2391d0"
                      class="ma-0"
                      hide-details>
                      <template v-slot:label>
                        <div
                          class="body-2">
                          Jadwalkan Diskusi
                        </div>
                      </template>
                    </v-checkbox>
                  </div>

                  <div v-if="form.is_schedule">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tanggal Mulai
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-menu
                      ref="start"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker.start">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider 
                          name="start_date" 
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            color="#2391d0"
                            placeholder="Contoh. 2023-01-01"
                            
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.start_date = ''; form.end_date = '';"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.start_date"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>

                      <v-date-picker 
                        color="#2391d0"
                        v-model="form.start_date"
                        @change="form.end_date = ''"
                        @input="picker.start = false">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <div v-if="form.is_schedule">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tanggal Selesai
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-menu
                      ref="end"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker.end">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider 
                          name="end_date" 
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            color="#2391d0"
                            placeholder="Contoh. 2023-01-02"
                            
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.end_date = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.end_date"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>

                      <v-date-picker 
                        color="#2391d0"
                        v-model="form.end_date"
                        :min="form.start_date"
                        @input="picker.end = false">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <div
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Lampiran
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-btn
                      @click="sheet.lampiran = true; index_lampiran = null; reset();"
                      rounded
                      color="#2391d033"
                      elevation="0"
                      class="text-capitalize color-default">
                      <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                      
                      <span
                        class="ml-1">
                        Lampiran
                      </span>
                    </v-btn>

                    <v-row
                      class="mt-2">
                      <v-col
                        v-for="(item, index) in form.lampiran"
                        :key="index"
                        cols="12"
                        class="pb-2">
                        <v-card
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-card-text>
                            <div
                              class="d-flex align-center">
                              <div>
                                <v-icon size="14" color="#2391d0">mdi-rhombus</v-icon>
                              </div>

                              <div
                                class="ml-2 one-line">
                                {{ item.title }}
                              </div>

                              <v-spacer />

                              <div 
                                @click="sheet.lampiran = true; index_lampiran = index; reset();"
                                class="cursor-pointer mr-2">
                                <v-icon size="24" color="blue">mdi-pencil-circle-outline</v-icon>
                              </div>

                              <div 
                                @click="form.lampiran.splice(index, 1)"
                                class="cursor-pointer">
                                <v-icon size="24" color="red">mdi-close-circle</v-icon>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="rapatfpr" 
                      :src="require('@/assets/icon/topic.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Lengkapi data Topik yang akan di diskusikan Anggota, kemudian klik <b class="font-italic">Selanjutnya</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      :disabled="process.form"
                      :loading="process.form"
                      v-on:keyup.enter="save"
                      @click="save"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Selanjutnya
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="goBack()"
                      :disabled="process.form"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.lampiran"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <v-card-title 
              @click="sheet.lampiran = false"
              class="cursor-pointer pa-4"
              style="background: #FFFFFF40; 
                    border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;">
              <v-icon 

                color="#2391d0">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 color-default">
                Lampiran
              </span>
            </v-card-title>

            <v-card
              flat
              color="transparent"
              style="margin-top: 57px;">
              <v-card-text
                class="pa-4"
                style="height: calc(100vh - 132px); 
                    overflow-y: auto;">
                <ValidationObserver 
                  ref="form_lampiran">
                  <v-form>
                    <ValidationProvider 
                      name="title" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Lampiran
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save_lampiran"
                        filled
                        rounded
                        dense
                        color="#2391d0"
                        placeholder="Contoh. Dokumen Pemetaan"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form_lampiran.title"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Data Lampiran
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-row>
                        <v-col
                          v-for="(item, index) in form_lampiran.data"
                          :key="index"
                          cols="12">
                          <v-card 
                            flat
                            width="100%"
                            height="auto"
                            color="transparent">
                            <div v-if="item.file_type === 'image'"
                              class="d-flex align-center justify-center"
                              style="
                                  width: 100%;
                                  height: auto;
                                  border-radius: 15px;
                                  border: 2px solid #0000000d;
                                  border-style: dashed;">
                              <v-img 
                                contain
                                alt="kominfo" 
                                width="100%"
                                height="600"
                                :src="item.file_url"
                                class="ma-auto"
                                style="border-radius: 15px;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#2391d0">
                                    </v-progress-circular>
                                  </v-row>
                                </template>

                                <div 
                                  @click="form_lampiran.data.splice(index, 1)"
                                  class="cursor-pointer"
                                  style="position: absolute; top: 5px; right: 5px;">
                                  <v-icon size="24" color="red">mdi-close-circle</v-icon>
                                </div>
                              </v-img>
                            </div>

                            <div v-else>
                              <iframe 
                                :src="`${item.file_url}#toolbar=0`" 
                                allowfullscreen
                                frameborder="0" 
                                width="100%"
                                height="600"
                                style="border-radius: 15px; border: 2px solid #323639;">
                              </iframe>
                            </div>
                          </v-card>
                        </v-col>

                        <v-col
                          cols="12">
                          <!-- <uploadfolder 
                            v-model="upload.file">
                            <div 
                              slot="activator" 
                              class="cursor-pointer">
                              <v-card 
                                flat
                                width="100%"
                                height="225"
                                color="transparent">
                                <div
                                  class="d-flex align-center justify-center"
                                  style="
                                      width: 100%;
                                      height: 225px;
                                      border-radius: 15px;
                                      border: 2px solid #0000000d;
                                      border-style: dashed;">
                                  <v-icon v-if="!process.file"
                                    size="50" 
                                    color="grey">
                                    mdi-cloud-upload-outline
                                  </v-icon>

                                  <v-progress-circular v-else
                                    indeterminate
                                    color="#2391d0">
                                  </v-progress-circular>
                                </div>
                              </v-card>
                            </div>
                          </uploadfolder> -->

                          <v-card 
                            @click="sheet.option = true"
                            flat
                            width="100%"
                            height="auto"
                            color="transparent">
                            <div
                              class="d-flex align-center justify-center"
                              style="
                                  width: 100%;
                                  height: 600px;
                                  border-radius: 15px;
                                  border: 2px solid #0000000d;
                                  border-style: dashed;">
                              <v-icon v-if="!process.file && !process.doc"
                                size="75" 
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>

                              <v-progress-circular v-else
                                indeterminate
                                color="#2391d0">
                              </v-progress-circular>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-card>

            <v-card
              flat
              color="#FFFFFF40"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn
                      @click="save_lampiran"
                      block
                      large
                      rounded
                      color="#348d37"
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="py-0">
                    <v-btn 
                      @click="sheet.lampiran = false; index_lampiran = null; reset();"
                      block
                      large
                      rounded
                      color="red"
                      outlined
                      elevation="0"
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.option"
          hide-overlay>
          <v-card
            flat
            color="#fff"
            class="d-flex align-center"
            style="border-radius: 30px 30px 0px 0px;">
            <v-card-text
              class="pa-3">
              <v-row 
                align="center" 
                justify="center">
                <!-- FOLDER -->
                <v-col 
                  cols="4" 
                  class="text-center">
                  <uploadfolder 
                    v-model="upload.file">
                    <div slot="activator">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="rapatfpr" 
                          :src="require('@/assets/image/folder.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div 
                        class="font-weight-bold black--text mt-2">
                        Gambar
                      </div>
                    </div>
                  </uploadfolder>
                </v-col>

                <v-col 
                  cols="4" 
                  class="text-center px-0">
                  <div 
                    class="d-flex align-center">
                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div 
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!--PDF -->
                <v-col 
                  cols="4" 
                  class="text-center">
                  <uploaddocument
                    v-model="upload.doc">
                    <div slot="activator">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="rapatfpr" 
                          :src="require('@/assets/image/pdf.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#2391d0">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div 
                        class="font-weight-bold black--text mt-2">
                        PDF
                      </div>
                    </div>
                  </uploaddocument>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          lampiran: false,
          option: false
        },
        upload: {
          folder: null,
          file: null,
          doc: null
        },
        list: [],
        pola_ruang: [],
        picker: {
          start: false,
          end: false
        },
        index_lampiran: null,
        form_lampiran: {
          title: '',
          data: []
        },
        process: {
          form: false,
          image: false,
          file: false,
          doc: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      uploaddocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue'),
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image = this.upload.folder.data.full.file_url
            }
          }
        },
        deep: true
      },
      'upload.file': {
        handler: function () {
          if (this.upload.file) {
            this.process.file = this.upload.file.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.sheet.option = false

              this.form_lampiran.data.push({
                file_url: this.upload.file.data.full.file_url,
                file_type: this.upload.file.type,
                process: this.upload.file.process
              })
            }
          }
        },
        deep: true
      },
      'upload.doc': {
        handler: function () {
          if (this.upload.doc) {
            this.process.doc = this.upload.doc.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD DOC

              this.sheet.option = false

              this.form_lampiran.data.push({
                file_url: this.upload.doc.data.file_url,
                file_type: this.upload.doc.type,
                process: this.upload.doc.process
              })
            }
          }
        },
        deep: true
      },
      'form.is_schedule': function(val) {
        if (!val) {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      }
    },
    mounted () {
      this.fetch_pola_ruang()
    },
    methods: {
      goBack () {
        this.$router.push({ path: '/forum'})
      },
      async fetch () {
        let params = {
          limit: 100000,
          td_type: this.form.extra.type_topic
        }

        this.form.extra.data_topic = []

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master/template_topic_data`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.list = response.data

            this.list.map(obj => {
              this.form.extra.data_topic.push({
                id: obj.id,
                td_id: obj.td_id,
                td_pos: obj.td_pos,
                td_title: obj.td_title,
                td_type: obj.td_type,
                td_create_datetime: obj.td_create_datetime,
                td_update_datetime: obj.td_update_datetime,
                td_form: obj.td_title !== 'Pola Ruang RTRW' ? '' : []
              })
            })
          } 
        })
      },
      async fetch_pola_ruang () {
        let params = {
          limit: 100000
        }

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/master/pola_ruang`, { params })
        .then((response) => {
          if (response.code === 200) {
            this.pola_ruang = response.data
          } 
        })
      },
      reset () {
        if (this.$refs.form_lampiran) this.$refs.form_lampiran.reset()
        
        if (this.index_lampiran === null) {
          this.form_lampiran = {
            title: '',
            data: []
          }
        } else {
          this.form_lampiran = this.form.lampiran[this.index_lampiran]
        }
      },
      async save_lampiran () {
        const isValid = await this.$refs.form_lampiran.validate()
        if (isValid) {
          if (this.index_lampiran === null) {
            this.form.lampiran.push(this.form_lampiran)
          } else {
            this.form.lampiran[this.index_lampiran] = this.form_lampiran
          }

          this.sheet.lampiran = false

          this.index_lampiran = null

          this.reset()
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_lampiran.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_lampiran.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async save () {
        this.message.error = ''
        
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.document_type = this.form.extra.type_topic

          this.form.is_schedule = !this.form.is_schedule ? 0 : 1

          let url = this.form.id === '' ? '/discussion/topic/create' : '/discussion/topic/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}${url}`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false
              
              this.$emit('step', 2)
              this.$router.push({ path: `/forum/form/${response.data.t_id}`, query: { step: 2 }})
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>

<style>
  .v-list-item__action {
    margin-bottom: 8px !important;
    margin-right: 10px !important;
  }
</style>