<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="font-weight-bold black--text">
              Profil Saya
            </v-card-title>

            <v-card-text
              class="my-4">
              <v-card
                class="border-radius"
                color="#FFFFFF40"
                style="box-shadow: rgba(35, 145, 208) 0px 25px 20px -20px;">
                <v-list 
                  flat 
                  color="transparent">
                  <v-list-item 
                    style="border-radius: 0px;">
                    <v-list-item-avatar 
                      size="45">
                      <v-img 
                        width="100%"
                        height="100%"
                        alt="rapatfpr" 
                        :src="detail.image === '' ? require('@/assets/image/member_default.jpg') : detail.image"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#2391d0">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title 
                        class="title font-weight-bold color-default mb-1">
                        {{ detail.fullname }}
                      </v-list-item-title>

                      <v-list-item-subtitle 
                        class="d-flex align-center black--text"
                        style="opacity: 60%;">
                        {{ detail.nik }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-card-text
                  class="d-flex align-center"
                  style="background: #2391d0;">
                  <div
                    class="caption white--text line-text-first"
                    style="width: 60%;">
                    Diskusikan permohonan PKKPR Berusaha dan PKKPR Nonberusaha yang diajukan oleh masyarakat.
                  </div>

                  <v-spacer />

                  <div>
                    <v-btn
                      to="/forum"
                      color="#FFFFFF40"
                      elevation="0"
                      rounded
                      class="body-2 font-weight-bold white--text text-capitalize">
                      Lihat Topik
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-list 
              flat 
              dense
              class="px-4"
              color="transparent">
              <v-list-item 
                v-for="(item, index) in menu"
                :key="index"
                @click="goTo(index)"
                class="px-0 py-2"
                :style="index < menu.length - 1 ? 'border-bottom: 1px dashed #bbb;' : ''">
                <v-list-item-avatar 
                  size="22"
                  tile
                  class="mr-3">
                  <v-img 
                    contain
                    alt="rapatfpr" 
                    :src="item.icon"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="#2391d0">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title 
                    class="body-2 font-weight-bold"
                    :class="index === menu.length - 1 ? 'red--text' : 'black--text'">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.menu"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <v-card-title 
              @click="goTo(selected.menu)"
              class="cursor-pointer pa-4"
              style="background: #eaf5f7; 
                    border-radius: 0px 0px 15px 15px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;">
              <v-icon 
                color="#2391d0">
                mdi-arrow-left-thick
              </v-icon>

              <span v-if="selected.menu !== null"
                class="body-1 font-weight-bold text-capitalize ml-1 color-default">
                {{ menu[selected.menu].title }}
              </span>
            </v-card-title>

            <v-card-text 
              class="pa-0">
              <History v-if="selected.menu === 0"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <General v-if="selected.menu === 1"
                :detail="detail"
                @close="sheet.menu = $event; fetch();" />

              <Password v-if="selected.menu === 2"
                @close="sheet.menu = $event; fetch();" />
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          menu: false
        },
        selected: {
          menu: null
        },
         menu: [
          {
            title: 'Riwayat Diskusi',
            icon: require('@/assets/image/profile/discussion.png'),
            link: '#'
          },
          {
            title: 'Ubah General',
            icon: require('@/assets/image/profile/profile.png'),
            link: '#'
          },
          {
            title: 'Ubah Password',
            icon: require('@/assets/image/profile/password.png'),
            link: '#'
          },
          {
            title: 'Keluar',
            icon: require('@/assets/image/profile/logout.png'),
            link: '/logout'
          }
        ],
        detail: {},
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profile',
          title: 'Profil Saya',
          description: 'Profil Saya Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      General: () => import(/* webpackPrefetch: true */ '@/components/profile/general.vue'),
      Password: () => import(/* webpackPrefetch: true */ '@/components/profile/password.vue'),
      History: () => import(/* webpackPrefetch: true */ '@/components/profile/history.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/profile`)
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.detail = response.data
          }
        })
      },
      goTo (index) {
        if (this.menu[index].link === '/logout') {
          Cookie.remove('token')
          Cookie.remove('user')
          Cookie.remove('fcm')

          if (this.detail.level !== 'admin') {
            window.location = '/'
          } else {
            window.location = '/admin'
          }

        } else {
          this.selected.menu = index

          this.sheet.menu = !this.sheet.menu ? true : false
        }
      }
    }
  }
</script>