<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            style="min-height: 100vh;">
            <v-row
              align="center"
              class="py-16"
              style="min-height:calc(100vh - 100px);">
              <v-col>
                <v-card  
                  flat
                  color="transparent">
                  <v-card-text
                    class="text-center">
                    <v-img
                      width="175"
                      height="auto"
                      class="ma-auto"
                      alt="vuetify"
                      :src="require('@/assets/logo/favicon.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#2391d0">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="bory-2 text-capitalize mb-2">
                      Selamat Datang Di
                    </div>

                    <div
                      class="text-h4 color-default mb-16">
                      Rapat FPR
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <ValidationObserver 
                      ref="form">
                      <v-form>
                        <ValidationProvider 
                          name="nik" 
                          rules="required|min:16|max:16"
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                            min="0"
                            filled
                            rounded
                            dense
                            color="#2391d0"
                            placeholder="NIK"
                            prepend-inner-icon="mdi-account-outline"

                            v-model="form.nik"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider 
                          name="password" 
                          rules="required|min:5|max:20" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            filled
                            rounded
                            dense
                            color="#2391d0"
                            placeholder="Password"
                            :type="show ? 'text' : 'password'"
                            prepend-inner-icon="mdi-lock-outline"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show = !show"
                            :counter="20"
                            
                            v-model="form.password"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>

                    <div v-if="message.error.length > 0"
                      class="text-center mt-3 mb-2 d-flex align-center">
                      <div
                        class="error--text">
                        <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                        
                        <span
                          class="ml-1">
                          {{ message.error }}
                        </span>
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text
                    class="d-flex align-center">
                    <v-btn
                      v-on:keyup.enter="save"
                      :disabled="process.form"
                      :loading="process.form"
                      @click="save"
                      width="150"
                      large
                      rounded
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize btn_auth">
                      <div
                        class="btn_auth_in">
                        Masuk
                      </div>
                    </v-btn>

                    <v-spacer />

                    <nuxt-link
                      to="/auth/forgot">
                      <div
                        class="body-2 text-center black--text"
                        style="opacity: 70%;">
                        Lupa Password
                      </div>
                    </nuxt-link>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div
              style="position: fixed; bottom: 25px; left: 0px; right: 0px;">
              <v-row
                justify="center">
                <v-col 
                  cols="2">
                  <v-divider 
                    style="border-top: 3px solid #4d585b; border-radius: 50px;" />
                </v-col>
              </v-row>

              <div
                class="body-2 text-center mt-2"
                style="opacity: 70%;">
                Copyright@{{ new Date().getFullYear() }} <strong>rapatfpr.klaten.go.id</strong>
              </div>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: false,
        form: {
          nik: '',
          password: '',
          fcm: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/',
          title: 'Masuk',
          description: 'Masuk Rapat FPR',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      if (Cookie.get().token !== undefined) window.location = '/home'
    },
    methods: {
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/auth/login`, this.form)
          .then((response) => {
            if (response.code === 200) {
              Cookie.set('token', response.data.token)
              Cookie.set('user', JSON.stringify(response.data))

              window.location = '/home'
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>